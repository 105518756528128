import React from 'react'
import img from "../../assets/images/aouthome.jpg"
import "../../index.css"

function About() {
  return (
    <div className='container mx-auto '>
        <div className='grid grid-cols-5'>
            <div data-aos="fade-right" className='sm:col-span-3 col-span-5 flex self-center'>
                <div>
                <h1 className='text-xl theme-font font-semibold'>About us</h1>
                <h1 className='font-black text-3xl font-bold pt-2 pb-3'>
                The Best IT Solution With 5 Years of Experience
                </h1>
                <p className='font-grey p-3'>Being a Certified Software Development business, WintecSoft Software Development Pvt Ltd provides custom software development services on a variety of technologies, including Big Data Analytics, Android and iOS Application Development, Microsoft, Oracle, PHP, Open Source, Cloud computing, and Mobile Apps. </p>

                <div className='flex justify-between mr-24 py-4'>
                    <div >
                    <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path  strokeLinecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>Award Winning</p>
                        </div>
                        <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path  strokeLinecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>24/7 Support</p>
                        </div>
                    </div>
                    <div >
                    <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path  strokeLinecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>Professional Staff</p>
                        </div>
                        <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path  strokeLinecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>Fair Prices</p>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                </div>
            </div>
            <div data-aos="fade-left" className=' flex justify-center sm:col-span-2 col-span-5 flex self-center'>
               <div className='flex justify-center'>
               <img src={img} alt="img" className='imss' />
               </div>
            </div>
        </div>
    </div>
  )
}

export default About