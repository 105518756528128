import React from 'react'
import "../../index.css"
import "./Home.css"
import { Link } from 'react-router-dom'

function Industries() {
  return (
    <div className='container mx-auto py-24'>
        <p className='py-3 font-small font-bold text-center theme-font '>INDUSTRIES</p>
        <h2 className='p-1 pb-3 font-large font-bold text-center'>Empowering Industries with<br/>Comprehensive Service<br/>Solutions</h2>
        <div className='grid grid-cols-6'>
            <div data-aos="fade-right" className='lg:col-span-2 md:col-span-3 col-span-6 m-2  rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac21 card-bac2 height100 rounded-xl">
  <div class=" p-6">
  <div class="font-large theme-font flex justify-center p-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M11.584 2.376a.75.75 0 01.832 0l9 6a.75.75 0 11-.832 1.248L12 3.901 3.416 9.624a.75.75 0 01-.832-1.248l9-6z" />
  <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 010 1.5H3a.75.75 0 010-1.5h.75v-9.918a.75.75 0 01.634-.74A49.109 49.109 0 0112 9c2.59 0 5.134.202 7.616.592a.75.75 0 01.634.74zm-7.5 2.418a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75zm3-.75a.75.75 0 01.75.75v6.75a.75.75 0 01-1.5 0v-6.75a.75.75 0 01.75-.75zM9 12.75a.75.75 0 00-1.5 0v6.75a.75.75 0 001.5 0v-6.75z" clip-rule="evenodd" />
  <path d="M12 7.875a1.125 1.125 0 100-2.25 1.125 1.125 0 000 2.25z" />
</svg>

    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">BFSI</h2>
    <p className='text-center'> The industry term for businesses that offer a variety of these financial products or services is banking, financial services, and insurance.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/bsfi"><button className='py-2 px-4 mt-4  button-border rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-down" className='lg:col-span-2 md:col-span-3 col-span-6 m-2  rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac22 card-bac2 height100 rounded-xl">
  <div class=" p-6">
  <div class="font-large theme-font flex justify-center p-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
</svg>

    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Education</h2>
    <p className='text-center'> The global education sector is undergoing significant transformation as a result of a number of factors, including government regulations</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/education"><button className='py-2 px-4 mt-4  button-border rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-left" className='lg:col-span-2 md:col-span-3 col-span-6 m-2  rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac23 card-bac2 height100 rounded-xl">
  <div class=" p-6">
  <div class="font-large theme-font flex justify-center p-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M12 6.75a5.25 5.25 0 016.775-5.025.75.75 0 01.313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 011.248.313 5.25 5.25 0 01-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 112.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0112 6.75zM4.117 19.125a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z" clip-rule="evenodd" />
  <path d="M10.076 8.64l-2.201-2.2V4.874a.75.75 0 00-.364-.643l-3.75-2.25a.75.75 0 00-.916.113l-.75.75a.75.75 0 00-.113.916l2.25 3.75a.75.75 0 00.643.364h1.564l2.062 2.062 1.575-1.297z" />
  <path fill-rule="evenodd" d="M12.556 17.329l4.183 4.182a3.375 3.375 0 004.773-4.773l-3.306-3.305a6.803 6.803 0 01-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 00-.167.063l-3.086 3.748zm3.414-1.36a.75.75 0 011.06 0l1.875 1.876a.75.75 0 11-1.06 1.06L15.97 17.03a.75.75 0 010-1.06z" clip-rule="evenodd" />
</svg>

    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Energy & Utilities</h2>
    <p className='text-center'> Accountable for the production, transmission, and distribution of water, natural gas, and electricity in a safe, secure...</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/energy"><button className='py-2 px-4 mt-4  button-border rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-right" className='lg:col-span-2 md:col-span-3 col-span-6 m-2  rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac24 card-bac2 height100 rounded-xl">
  <div class=" p-6">
  <div class="font-large theme-font flex justify-center p-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
</svg>
    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">HealthCare</h2>
    <p className='text-center'> One of the most important sectors in the world, healthcare has experienced tremendous growth in the last ten years. </p>
  </div>
  <div className='flex justify-center'>
    <Link to="/health"><button className='py-2 px-4 mt-4  button-border rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-up" className='lg:col-span-2 md:col-span-3 col-span-6 m-2  rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac25 card-bac2 height100 rounded-xl">
  <div class=" p-6">
  <div class="font-large theme-font flex justify-center p-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 18.375V5.625zm1.5 0v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-1.5A.375.375 0 003 5.625zm16.125-.375a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5A.375.375 0 0021 7.125v-1.5a.375.375 0 00-.375-.375h-1.5zM21 9.375A.375.375 0 0020.625 9h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5zm0 3.75a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 00.375-.375v-1.5zM4.875 18.75a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375h1.5zM3.375 15h1.5a.375.375 0 00.375-.375v-1.5a.375.375 0 00-.375-.375h-1.5a.375.375 0 00-.375.375v1.5c0 .207.168.375.375.375zm0-3.75h1.5a.375.375 0 00.375-.375v-1.5A.375.375 0 004.875 9h-1.5A.375.375 0 003 9.375v1.5c0 .207.168.375.375.375zm4.125 0a.75.75 0 000 1.5h9a.75.75 0 000-1.5h-9z" clip-rule="evenodd" />
</svg>
    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Media & Entertainment</h2>
    <p className='text-center'> The global media and entertainment industry is undergoing a digital revolution.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/media"><button className='py-2 px-4 mt-4  button-border rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-left" className='lg:col-span-2 md:col-span-3 col-span-6 m-2  rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac26 card-bac2 height100 rounded-xl">
  <div class=" p-6">
  <div class="font-large theme-font flex justify-center p-2">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clip-rule="evenodd" />
</svg>

    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Oil & Gas mining</h2>
    <p className='text-center'>Everything that goes into finding, assessing, and extracting metals, minerals, petroleum, and fossil fuels from the earth.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/oil"><button className='py-2 px-4 mt-4  button-border rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Industries