import React, { useEffect } from 'react'
import Navbar from '../Home/Navbar'
import Locationpage from './Locationpage'
import Footer from '../Footer/Footer'
import Icons from '../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Location() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Locationpage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Location