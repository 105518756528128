import React, { useRef, useState } from 'react'
import "./Contact.css"
import toast, { Toaster } from 'react-hot-toast'
import emailjs from '@emailjs/browser';

function Contactuspage() {
  const form = useRef()
  const [isname, setisname] = useState(false)
  const [isemail, setisemail] = useState(false)
  const [iscontact, setiscontact] = useState(false)
  const [isdescription, setisdescription] = useState(false)
  const [cover, setcover] = useState("")
  const [iscover, setiscover] = useState(false)
  const [name, setname] = useState("")
  const [email, setemail] = useState("")
  const [contact, setcontact] = useState("")
  const [description, setdescription] = useState("")
  const nameHandler = (event)=>{
    setname(event.target.value)
    setisname(false)
  }
  const emailHandler = (event)=>{
    setemail(event.target.value)
    setisemail(false)
  }
  const contactHandler = (event)=>{
    setcontact(event.target.value)
    setiscontact(false)
  }
  const descriptionHandler = (event)=>{
    setdescription(event.target.value)
    setisdescription(false)
  }
  // const coverHandler = (event)=>{
  //   setcover(event.target.value)
  //   setiscover(false)
  // }
  const h1bHandler = (event) => {
    event.preventDefault();
  
   if(name.length < 3 || email.includes("@") === false){
    
    if (name.length < 3) {
      setisname(true);
    }
    if (email.includes("@") === false) {
      setisemail(true);
    }
   }else{
    emailjs.sendForm('service_8bza5xe', 'template_jb0kbzm', form.current, '5MKQxHFs8bcYCsx-k')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    toast.success(<p className='text-center'>Successfully sent <br/> Wintechsoft will contact you soon</p>)
    setcontact("")
    setname("")
    setemail("")
    setdescription("")

   }
    // if (contact.length < 10 || contact.length >10) {
    //   setiscontact(true);
    // }
    // if (cover.length < 10) {
    //   setiscover(true);
    // }
    // if (description.length < 5) {
    //   setisdescription(true);
    // }
  };
  return (
    <div>
      <Toaster position="top-right"
  reverseOrder={false} />
        <div>
        <div className='flex items-center justify-center bannercontact'>
            <div>
            <h1 className='font-white text-center py-2 font-bold font-large'>Contact Us</h1>
            <p className='font-white text-center '> This is WintecSoft Contact page</p>
            </div>
        </div>
    </div>
    <div  data-aos="fade-up" data-aos-duration="1000" className='container mx-auto pt-14 '>
        <h3 className='text-center font-small font-bold theme-font pt-14'>CONTACT US</h3>
        <h1 className='text-center font-large py-3 font-bold'> If You Have Any Query, Feel<br /> Free To Contact Us</h1>
    
        <div className='grid grid-cols-6 pt-14'>
            <div className='col-span-6 md:col-span-6 sm:pt-8 lg:col-span-2 py-4 '>
                <div className='flex items-center justify-center'>
                    <div className='font-bold font-white font-large theme-bg m-2 p-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
</svg>
                    </div>
                    <div>
                        <p className='font-small'>Email to get free quote</p>
                            <p className='font-small font-bold'>hr@wintecsoft.net</p>
                    </div>
                </div>
            </div>
            <div className='col-span-6 md:col-span-3 sm:pt-8 lg:col-span-2 py-4 pt-14 sm:pt-0'>
                <div className='flex items-center justify-center'>
                    <div className='font-bold font-white font-large theme-bg m-2 p-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path  strokeLinecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path  strokeLinecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>
                    </div>
                    <div>
                        <p className='font-small'>Visit our office</p>
                            <p className='font-small font-bold'>1320 Arrow Point Dr Suite 501 # TW115 Cedar Park,
TX 78613</p>
                    </div>
                </div>
            </div>
            <div className='col-span-6 md:col-span-3 sm:pt-8 lg:col-span-2 pt-4 sm:pt-0'>
                <div className='flex items-center justify-center'>
                    <div className='font-bold font-white font-large theme-bg m-2 p-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path  strokeLinecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path  strokeLinecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>
                    </div>
                    <div>
                        <p className='font-small'>Visit our office</p>
                            <p className='font-small font-bold'>RAM SVR, Plot No 4/2, Sector 1, Madhapur,<br /> HUDA Techno Enclave, HITEC City, Hyderabad, <br />Telangana 500081</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="zoom-in" className='container mx-auto'>
    <div className='flex justify-center pt-24'>
    <form className="w-full max-w-lg" ref={form} onSubmit={h1bHandler}>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-2">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Your Name
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" onChange={nameHandler} id="grid-first-name" value={name} name='name' type="text" placeholder="name" />
      {isname && <p className='text-red-600 text-sm'>Invalid name</p>}
    </div>
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Enter Email
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded mb-3 py-3  px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name"  onChange={emailHandler} value={email} name="email" type="email" placeholder="example@gmail.com"/>
      {isemail && <p className='text-red-600 text-sm'>Invalid Email</p>}
    </div>
  </div>
    <div className="w-full mb-6">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Contact Number
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" pattern="\d*" maxlength="10" name='contact' onChange={contactHandler} value={contact}  placeholder="123 123 1234"/>
    </div>
  <div>
  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Description
      </label>
      <textarea  onChange={descriptionHandler}
    name="description"
    value={description} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" maxlength="500" id="grid-password" rows={4} ></textarea>
    <div className='flex justify-center'>
      <button className='btn py-2 px-3 text-white bg-black border rounded-xl'>Submit</button>
    </div>
  </div>
</form>
    </div>

    </div>
    </div>
  )
}

export default Contactuspage