import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import Datapage from '../../Technologies/Datascience/Datapage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function DataScience() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Datapage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default DataScience