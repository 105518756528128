import React from 'react'
import "./UIUX.css"
import img from "../../../assets/images/uiux.png"
import { Link } from 'react-router-dom'

function UIUXpage() {
  return (
    <div>
    <div className='flex items-center justify-center banneruiux'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Services</h1>
        <p className='font-white text-center '>UI And UX Development</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>UI And UX Development</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The term "user experience" (UX) describes how people engage with software, including the user interface (UI), design, visuals, and multi-device interaction. The user interface of software, a mobile application, or a website is just as vital as its functionality in today's cutthroat market. No matter how good the features are, an app won't succeed if it can't be used. Above all, user interface and UX design guarantees the software's success by boosting user satisfaction and conversion rates.
                    </p>
                <p className='font-grey py-2'>
                We provide the best UI and UX design and development thanks to our team of UX specialists and years of experience.
The Services follow all applicable procedures and policies. We offer complete interface UX design services, such as:
                </p>
                <p className='font-small font-bold'>Information architecture design</p>
                <p className='font-grey font-bold'>User action plan design (interaction design)</p>
                <p className='font-grey font-bold '>Wireframe designing</p>
                <p className='font-grey font-bold '>Graphics (Visual design)</p>
                <p className='font-grey font-bold '>Graphics (Visual design)</p>
                <p className='font-grey font-bold '>User testing</p>
                <p className='font-bold font-large py-3'>SERVICES</p>
                <p className='font-grey py-2'>
                The logical arrangement of system information to make it consistent, useable, and intuitive is known as information architecture design. Our UX design team creates the system so that people can quickly and easily find what they're looking for.
                </p>
                <p className=' font-grey  py-2'>
                The exchange of information via the user interface between the user and the systems is called interaction. These kinds of interactions can be found by our user experience design specialists, who can then develop a system roadmap that fits your information architecture, information flow, user expectations, and business objectives.
                </p>
                <p className='font-grey py-2'>"Design is not just how it looks and feels. Design is how it works." - Steve Jobs</p>
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">ios Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default UIUXpage