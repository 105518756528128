import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/vedu.webp"
import { Link } from 'react-router-dom'

function Educationpage() {
  return (
    <div>
    <div className='flex items-center justify-center banneredu bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Education</p>
        </div>
    </div>
    <div>
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Education</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Education</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p  className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The wide range of educational services, goods, and technologies offered to students worldwide is referred to as the global education industry. This covers both formal education received in classrooms, colleges, and technical schools, as well as informal education obtained through tutoring programs, online learning environments, and other individualized learning methods.
                </p>
                <p className='font-grey py-2'>
                Recent technological advancements, shifting student demands and preferences, and rising need for possibilities for lifelong learning have all had a major impact on the sector. As a result, novel approaches to teaching and learning as well as new business models have emerged. Examples of these include online and blended learning models, individualized and adaptive learning technology, and experiential learning opportunities.
                </p>
                    <p className='font-large font-bold py-3'> Education</p>
                <p className='font-grey py-2'>
                Teaching, training, research, publishing, and other related activities are all included in the vital sector known as education. It includes educational establishments like schools, colleges, universities, career centers, and suppliers of online education. Education publishers, who produce and disseminate learning resources like textbooks, journals, and online courses, are another part of the sector.
Gainful, demanding, and influential work can be found in the education sector.
                </p>
                <p className='font-grey py-2'>
                Many career options, including those of teachers, professors, administrators, counselors, and support staff, are available in educational institutions. Different skill sets, degrees of education, and experience are needed for these positions. You can be involved in the production, editing, marketing, sales, and distribution of educational materials as a staff member of an education publisher. Through the creation and distribution of instructional resources that enhance teaching and learning, publishers play a crucial role in the sector. They work together with writers, instructors, and subject matter experts to provide instructive materials that are both current and interesting.
                </p>
                <p className='font-grey py-2'>
                Ultimately, whether you work for a publisher or an educational institution, a career in education may be significant and gratifying. It calls for a love of learning, a dedication to quality, and the flexibility to adjust to new circumstances.Services
Mobile Data Science Advertising Development of Android Apps Online Advertising iOS Application Development Java
                </p>
                

              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Educationpage