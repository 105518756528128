import React from 'react'
import Navbar from '../../Home/Navbar'
import ShipingPage from './ShipingPage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'

function Shiping() {
  return (
    <div><Navbar />
    <div className='pb-14'>
        <ShipingPage />
    </div>
    <Icons/>
    <Footer />
    </div>
  )
}

export default Shiping