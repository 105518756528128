import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import BigDataPage from './BigDataPage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"
const BigData = () => {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <BigDataPage/>
        </div>
        <Icons />
        <Footer />
    </div>
  )
}

export default BigData