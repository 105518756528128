import React from 'react'
import "./Contact.css"

function Locationpage() {
  return (
    <div>
        <div className='flex items-center justify-center bannerlocatoin'>
            <div>
            <h1 className='font-white text-center py-2 font-bold font-large'>Our Location</h1>
            <p className='font-white text-center '> This is WintecSoft Location page</p>
            </div>
        </div>
        <div data-aos="fade-down" data-aos-duration="1000" className='pt-12 '>

        <p className='font-bold theme-font font-small text-center py-3'>Our Locations</p>
        <p className=' font-large text-center py-3'>Global Presence</p>

        <h1 className='text-center font-large theme-font font-bold '>USA</h1>
            <br />
            <h1 className='text-center font-large font-bold py-4'>WINTECSOFT LLC</h1>
            <p className='text-center'>
            1320 Arrow Point Dr Suite 501 # TW115 Cedar Park,<br /> TX 78613
            </p>
            <div className='grid grid-cols-4 py-8'>
                <div className=' sm:col-span-1'></div>
                <div data-aos-duration="1000" data-aos="zoom-in" className='col-span-4 sm:col-span-2'>
                <iframe className='w-full px-2' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3436.489182237296!2d-97.80386892522856!3d30.535480295140257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b2cdb569258df%3A0x929abb005fa02349!2sT-Werx%20Coworking!5e0!3m2!1sen!2sin!4v1702032387247!5m2!1sen!2sin"  height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>           
                </div>
                <div className=' sm:col-span-1'></div>
             </div>
        </div>
        <hr  className='container mx-auto py-8'/>
        <div data-aos-duration="1000" data-aos="fade-up">
            <h1 className='text-center font-large theme-font font-bold '>INDIA</h1>
            <br />
            <h1 className='text-center font-large font-bold py-4'>WINTECSOFT LLC</h1>
            <p className='text-center'>RAM SVR, Plot No 4/2, Sector 1, Madhapur, HUDA Techno Enclave,<br />
             HITEC City, Hyderabad, Telangana 500081</p>
             <div className='grid grid-cols-4 py-8'>
                <div className=' sm:col-span-1'></div>
                <div data-aos-duration="1000" data-aos="zoom-in" className='col-span-4 sm:col-span-2'>
                <iframe className='w-full px-2' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.3155205231496!2d78.38511179999999!3d17.444606099999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb915fef8e50af%3A0x57cd8153f2a67af7!2sRENT%20A%20DESK%20-%20Hitec%20City!5e0!3m2!1sen!2sus!4v1701517826592!5m2!1sen!2sus"  height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className=' sm:col-span-1'></div>
             </div>
           
        </div>
    </div>
  )
}

export default Locationpage