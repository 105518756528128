import React from 'react'
import "./Mobile.css"
import img from "../../../assets/images/vmobil.jpg"
import { Link } from 'react-router-dom'

function Mobilepage() {
  return (
    <div>
    <div className='flex items-center justify-center bannermobile'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Services</h1>
        <p className='font-white text-center '> Mobile Application Development Company</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Mobile Application Development Company</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The lives of humans have profoundly transformed by mobile technology. Businesses benefit from its unparalleled ability to reach a larger audience, higher service standards, enhanced staff collaboration and communication, and support for business transformation prospects within the company. Enterprise mobility broadens a company's worldwide reach and gives stagnant business procedures more dynamism. These days, creating mobile business apps is essential to your whole business plan.
                </p>
                <p className='font-grey py-2'>
                WintecSoft Software Development Pvt Ltd offers custom mobile app development services. Since its founding, the mobile app development company WintecSoft software Development Pvt Ltd has produced a number of innovative products. Our in-house custom mobile app development services are entirely tailored to your needs, resulting in an exceptional end product. Our mobile app developer is well-versed in both iOS and Android development, and he has a ton of expertise creating the UI/UX of mobile apps. For small and large organizations alike, he has created dozens of native, hybrid, and enterprise apps. Our customers now have more options and are able to accomplish their business objectives thanks to our excellent, reasonably priced solutions.
                </p>

                <p className='font-grey py-2'>
                With years of experience in many different technologies and domains, Enterprise has developed software that helps businesses cut expenses, improve productivity, and streamline processes.
                </p>
                <p className=' font-large font-bold py-3'>
                    Service
                </p>

                <p className='font-grey p-1'>Our Mobile Apps Development Services:</p>
                <p className='font-grey '>iOS Development</p>
                <p className='font-grey '>Android Development</p>
                <p className='font-grey '>Windows Development</p>
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Mobilepage