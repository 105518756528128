import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/vios.jpg"
import { Link } from 'react-router-dom'

function IOSpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerios bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>IOS </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>IOS </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>IOS   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Apple Inc. created the iOS mobile operating system for its iPhone, iPad, and iPod Touch devices. It is renowned for having an elegant and simple-to-use interface and for integrating seamlessly with other Apple goods and services.
                </p>
                <p className='py-2 font-grey'>
                Many features are available with iOS, including as the built-in App Store for downloading and updating apps, Apple Music, iMessage, and the Safari web browser, among other pre-installed apps. Additionally, there is a virtual assistant named Siri. Additionally, it provides a range of security features like Face ID, Touch ID, and user data encryption.
                </p>
                <p className='font-grey py-2'>
                Users can get security upgrades, bug fixes, and new features from iOS releases on a regular basis. As of September 2021, the cutoff date, to the best of my knowledge, iOS 15 is the most recent version of the operating system. The vast array of excellent apps that are available for iOS, along with its seamless interaction with other Apple products and services, contribute to the platform's widespread use worldwide.
                </p>
                <p className='py-2 font-grey'>
                Our staff has experience developing iPhone apps and is technically proficient in providing services such as:
                </p>
                
                <p className='py-1 font-grey'>Custom iOS app development</p>
                <p className='font-grey py-1'>
                iOS game development     
                </p>
                <p className='font-grey py-1'>
                iOS app testing and quality assurance
                </p>
                <p className='font-grey py-1'>
                iOS app maintenance and support:
                </p>
                <p className='font-grey py-1'>
                iOS app porting and migration

                </p>
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default IOSpage