import React from 'react'
import "../../index.css"
import "./About.css"

function AboutWintech() {
  return (
    <div className='grid grid-cols-6'>
      <div className='col-span-1'></div>
      <div className='col-span-6 p-2 sm:col-span-4'>
      <div className='container mx-auto aboutwin'>
            <p className='font-large theme-font text-center pb-3 font-bold'>WintecSoft software Deployment</p>
            <div className='flex justify-center winlist'>
                <div>
                    <p className='font-large'>WintecSoft software Deployment</p>
                    <p className='font-grey py-2'> Reputable and licensed IT firm offering software project services and successful projects.</p>
                    <p>Why choose WintecSoft software Development Pvt Ltd as your global software outsourcing partner:</p>
                    <div className='py-3'>
                    <ul className='font-grey rounded-xl backdrop-blur-sm bg-white/30 '>
                        <li> Software development company</li>
                        <li>Web development and SEO</li>
                        <li>Mobile advertising and digital marketing</li>
                        <li> Big data analysis and iOS application development</li>
                        <li>Global presence and diverse expertise</li>
                    </ul>
                    </div>
                    <p className='font-grey py-2'>A single location to get everything your company needs, including software development, digital marketing, web and mobile development, and UI/UX design.</p>
                    <p className='font-grey py-2'>As well as cutting-edge innovations like chatbots, cloud computing, IoT, big data, artificial intelligence, and Python..</p>
                </div>
            </div>
    </div>
      </div>
      <div className='col-span-1'>

      </div>
    </div>
  )
}

export default AboutWintech