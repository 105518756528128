import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/vhealth.jpg"
import { Link } from 'react-router-dom'

function Healthpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerHealth bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Healthcare Software Development Experience</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Healthcare Software Development Experience</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Healthcare Software Development Experience</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The rapidly expanding field of healthcare software development is transforming the way medical professionals provide patient care. The creation of digital tools that help expedite patient care, strengthen provider communication, and improve patient outcomes is the process of developing healthcare software
                </p>
                <p className='font-grey py-2'>
                Creating healthcare software necessitates a thorough comprehension of the intricate healthcare sector, including the legal and regulatory frameworks governing the use of patient data. In addition, it calls for proficiency in data analysis and user experience design, as well as a variety of database management systems, software frameworks, and programming languages.
                </p>
                <p className='font-grey py-2'>
                Requirements gathering, design, programming, testing, and deployment are some of the stages that are usually involved in the software development process for the healthcare industry. To guarantee that the program satisfies their demands, the development team must collaborate extensively with healthcare providers and other stakeholders.
                </p>
                <p className='font-grey py-2'>
                Electronic medical records (EMRs), telemedicine platforms, medical imaging software, patient portals, and healthcare analytics tools are a few examples of popular healthcare software applications. These apps have the potential to decrease medical errors, increase patient outcomes, and streamline the delivery of healthcare.
                </p>
                    <p className='font-large font-bold py-3'>Healthcare Software Development Experience</p>
                <p className='font-grey py-2'>
                Developing software programs especially for use in the healthcare sector is known as healthcare software development. These applications can include medical billing and coding software, telemedicine platforms, and electronic health record systems.
Healthcare software developers must understand the particular difficulties and demands faced by the sector, including adhering to legal regulations such as HIPAA (Health Insurance Portability and Accountability Act) and protecting patient privacy and security. They also need to take user experience, data analysis and reporting capabilities, and interoperability with other systems into account.
                </p>
                <p className='font-grey py-2'>
                Software development for the healthcare industry frequently uses a number of technologies and programming languages, such as MySQL and MongoDB, as well as Java, Python, C++, Ruby on Rails, and JavaScript. Moreover, developers can use machine learning and artificial intelligence to build intelligent healthcare systems that can analyze enormous volumes of data and offer insights that can enhance patient outcomes.
                </p>
                <p className='font-grey py-2'>
                In general, developing software for the healthcare sector necessitates a solid basis in programming and software development concepts, as well as a thorough grasp of the sector and its particular difficulties.
                </p>
                

              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Healthpage