import React from 'react'
import "./Cloud.css"
import img from "../../../assets/images/vcloud.jpg"
import { Link } from 'react-router-dom'

function CloudPage() {
  return (
    <div>
    <div className='flex items-center justify-center bannercloud'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Services</h1>
        <p className='font-white text-center '> Cloud Management Services</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Cloud Management Services</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Enterprises need software that is simply accessible, scalable, and economical in today's dynamic global business climate. Cloud technologies, with their on-demand capabilities, speed, agility, scalability, responsiveness, and most importantly, cost effectiveness, meet this goal in a unique way.
                </p>
                <p className='font-grey py-2'>
                While many organizations are prepared to profit from cloud computing, they frequently lack the knowledge of how to proceed. With years of experience, our cloud application developers can provide the best cloud solutions based on your needs and long-term company objectives. Using cloud platforms, he has effectively delivered his ERP and other SaaS-based applications. We offer Office365, Amazon Web Services (AWS), Microsoft Azure, and other solutions. Additionally, we provide Salesforce® integration and customisation services.</p>                <p className='theme-font font-small py-3'>
                Experience with energy management software                </p>

                <p className='font-grey py-2'>
Enterprise designs his solutions with years of experience in software development and a breadth of knowledge in various technologies and areas to help businesses improve productivity, cut expenses, and streamline processes.</p>
                <p className='font-grey py-2'>
                We provide end-to-end enterprise solution development services including but not limited to:
                </p>

                <p className='font-grey '>Enterprise application integration</p>
                <p className='font-grey '>application outsourcing</p>
                <p className='font-grey '>business intelligence</p>
                <p className='font-grey '>Customer relationship management (CRM)</p>
                <p className='font-grey '>Enterprise Content Management (ECM)</p>
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default CloudPage