import React from 'react'
import "../../index.css"
import "./About.css"

function Vision() {
  return (
    <div className='grid grid-cols-6'>
      <div className='col-span-1'></div>
      <div className='col-span-6 p-2 sm:col-span-4'>
      <div className='container mx-auto aboutwin'>
            <p className='font-large theme-font text-center pb-3 font-bold'>Vision & Mission</p>
            <div className='flex justify-center winlist'>
                <div>
                    <p className='font-large'>WintecSoft software Deployment</p>
                    <p className='font-grey py-2'> We are well-known throughout the world as an IT outsourcing company that offers top-notch software services. </p>
                    <p className='font-large'>Mission</p>
                    <p>Supplying the world's market with exceptional software services via ongoing technological innovation, knowledge sharing, and work-critical attitude to make the world a better place while allowing us to consistently increase our assets and income. By fostering an enjoyable and supportive environment for all of our stakeholders, we accomplish our goals.</p>
                    <div className='py-3'>
                    <ul className='font-grey rounded-xl backdrop-blur-sm bg-white/30'>
                        <div>
                            <p className='font-small font-bold theme-font'>Values</p>
                            <p className='py-2 font-grey'>
                            We work hard to deliver cutting-edge, useful, and dependable technical solutions along with industry best practices.We have experts in every facet of software development on our team. - Alliance Technology United • We cherish and foster our relationships with each and every employee and customer. We respect each person's values, foster an environment of openness, and guarantee strong internal relationships and teamwork. We're determined to uphold our moral principles. - Alliance Technology United
                            </p>
                        </div>
                        <li> Our values ​​are reflected below</li>
                        <li> Build lasting and profitable relationships with customers</li>
                        <li>Confidentiality of works</li>
                        <li>An atmosphere of learning and growth express sincerity</li>
                        <li> Independent and credible business target</li>
                    </ul>
                    </div>
                    <p className='font-grey py-2'>A single location to get everything your company needs, including software development, digital marketing, web and mobile development, and UI/UX design.</p>
                    <p className='font-grey py-2'>As well as cutting-edge innovations like chatbots, cloud computing, IoT, big data, artificial intelligence, and Python..</p>
                </div>
            </div>
    </div>
      </div>
      <div className='col-span-1'>

      </div>
    </div>
  )
}

export default Vision