import React from 'react'
import Navbar from '../../Home/Navbar'
import Healthpage from './Healthpage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'

function Health() {
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Healthpage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Health