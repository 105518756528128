import React from 'react'
import "../../index.css"
import img from "../../assets/images/choose.jpg"
import "./Home.css"

function Choose() {
  return (
    <div className='container mx-auto py-24'>
        <div>
            <p className='text-center theme-font font-lg font-small font-bold'>Why Choose Us</p>
            <h1 className='py-3 text-center font-large font-bold'>We Are Here to Grow Your <br/> Services Exponentially</h1>
        </div>
        <div className='grid grid-cols-6 m-3'>
            <div data-aos="fade-right" className=' col-span-6 sm:col-span-3 md:col-span-2'>
                <div className='py-8'>
                    <div className='p-4 theme-bg inline-block font-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path  strokeLinecap="round" stroke-linejoin="round" d="M12 3.75v16.5M2.25 12h19.5M6.375 17.25a4.875 4.875 0 004.875-4.875V12m6.375 5.25a4.875 4.875 0 01-4.875-4.875V12m-9 8.25h16.5a1.5 1.5 0 001.5-1.5V5.25a1.5 1.5 0 00-1.5-1.5H3.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5zm12.621-9.44c-1.409 1.41-4.242 1.061-4.242 1.061s-.349-2.833 1.06-4.242a2.25 2.25 0 013.182 3.182zM10.773 7.63c1.409 1.409 1.06 4.242 1.06 4.242S9 12.22 7.592 10.811a2.25 2.25 0 113.182-3.182z" />
</svg>
                    </div>
                    <h3 className='font-bold py-2 text-xl'>Best In Industry</h3>
                    <p className='font-grey '>
                    A website promotes your brand to potential clients and aids in brand awareness creation.
                    </p>
                </div>
                <div>
                    <div className='p-4 theme-bg inline-block font-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" /></svg>
                    </div>
                    <h3 className='font-bold py-2 text-xl'>Award Winning</h3>
                    <p className='font-grey '>
                    Honor those who uphold excellent customer service, stimulate satisfaction, and support businesses.                    </p>
                </div>
            </div>
            <div data-aos="zoom-in" className=' flex justify-center col-span-2 md:block hidden mx-2 choose-img w-auto'>
                <div className='flex justify-center'>
                <img src={img} alt="img" />
                </div>
            </div>
            <div data-aos="fade-left" className=' col-span-6 sm:col-span-3 md:col-span-2'>
                <div className='py-8'>
                    <div className='p-4 theme-bg inline-block font-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path  strokeLinecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" /></svg>
                    </div>
                    <h3 className='font-bold py-2 text-xl'>Professional Staff</h3>
                    <p className='font-grey '>
                    Rather than offering a physical, tangible product, they sell knowledge and concepts to assist companies in becoming prosperous.                    </p>
                </div>
                <div>
                    <div className='p-4 theme-bg inline-block font-white'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path  strokeLinecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
</svg>
                    </div>
                    <h3 className='font-bold py-2 text-xl'>24/7 Support</h3>
                    <p className='font-grey '>
                    No matter the day or time, a customer can always get their issue resolved with a 24/7 support model.                    </p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Choose