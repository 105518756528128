import React from 'react'
import "./BigData.css"
import img from "../../../assets/images/vdata.jpg"
import { Link } from 'react-router-dom'

function BigDataPage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerbig'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Services</h1>
        <p className='font-white text-center '> Big Data & Analytics</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Big Data & Analytics</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Due to the exponential growth of enterprises in recent decades, enormous amounts of structured and unstructured data are being produced by numerous heterogeneous systems. Consolidating all of the data onto a single platform and analyzing it to gain insights that can actually advance the business has become extremely difficult as a result of big data.                </p>
                <p className='font-grey py-2'>
                Wintecsoft Software Development Pvt Ltd, a Big Data consulting company, helps businesses overcome these obstacles by providing Big Data and Analytics solutions that connect various systems, compile data, and display it to customers on an easy-to-use dashboard. important corporate data that enhances tactics and overall effectiveness.                </p>
                <p className='font-grey py-2'>
                We have been providing business intelligence (BI) and big data consulting services to top companies and independent manufacturers across many industries for over a decade. Using the Pentaho Development stack and additional community software like Jaspersoft and Talent, we provide a broad range of services at all stages of the business intelligence stack, including data integration, data cleansing, ETL, database configuration, OLAP, data mining, predictive analytics, reporting, and dashboards.                </p>
                <p className='font-grey py-2'>
                Through2017, the market for business analytics software is anticipated to expand at a CAGR of 9.7% worldwide. Gartner
We provide a range of business analytics and big data management consulting services, including but not restricted to:                </p>
              <p className='font-bold'>BI Consulting
Reporting/Dashboards
Information management</p>
              </div>

              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default BigDataPage