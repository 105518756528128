import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import Energypage from './Energypage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"
function Energy() {
  useEffect(()=>{
   Aos.init() 
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Energypage />
        </div>
        <Icons />
        <Footer />
    </div>
  )
}

export default Energy