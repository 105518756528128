import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/java.png"
import { Link } from 'react-router-dom'

function Javapage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerjava bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>JAVA Software Development Company</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>JAVA Software Development Company</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>JAVA Software Development Company</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Because Java allows code to be turned into platform-independent bytecode and executed on any device with the Java Virtual Machine (JVM) installed, the language is well-known for its "write once, run anywhere" philosophy. Because of this, Java is a popular option for creating desktop, mobile, and web apps that must function across many platforms.
                </p>
                <p className='py-3 font-bold'>Some of the key features of Java include:</p>
                <p className='py-2 font-grey'>
                Java is made to facilitate object-oriented programming (OOP), which includes ideas like polymorphism, inheritance, and encapsulation. Memory management: Java takes care of memory allocation and deallocation automatically, which makes writing code free of memory leaks and other memory-related errors easier.
                </p>
                <p className='font-grey py-2'>
                Java has a strong mechanism in place to handle errors and exceptions in programs. Security: Java comes with built-in security capabilities, such as a security manager that can limit what can be done by Java code that is executed in a sandbox.
                </p>
                
                <p className='py-3 font-bold'>JAVA APPLICATION DEVELOPMENT SERVICES</p>
                <p className='font-grey py-1'>
                Java web application development:
                </p>
                <p className='font-grey py-1'>
                Java mobile application development
                </p>
                <p className='font-grey py-1'>
                Java software development:
                </p>
                <p className='font-grey py-1'>
                Java migration and modernization:
                </p>
                <p className='font-grey p-1'>
                    Java integration services:
                </p>
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Javapage