import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import CloudPage from './Cloudpage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'

import Aos from 'aos'
import "aos/dist/aos.css"
function Cloud() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <CloudPage/>
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Cloud