import React, { useEffect } from 'react'
import ProductBanner from './ProductBanner'
import Navbar from '../../Home/Navbar'
import Footer from "../../Footer/Footer"
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Product() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
        <ProductBanner />
        </div>
        <Icons />
        <Footer />
    </div>
  )
}

export default Product