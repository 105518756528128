import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/vsalesforce.jpg"
import { Link } from 'react-router-dom'

function Salesforcepage() {
  return (
    <div>
    <div className='flex items-center justify-center bannersale bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>SalesForce </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>SalesForce </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto pt-8'>
            {/* <p className='font-theme text-center font-bold py-3'>SalesForce   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Wintecsoft Software – Salesforce Development Company</p>
                <p className='font-grey py-2'>
                Salesforce is a cloud-based customer relationship management (CRM) tool that helps companies better manage their sales operations and client interactions. Salesforce development entails setting up and customizing the platform to satisfy certain company requirements. Creating custom fields, making bespoke apps, and integrating with other systems are a few examples of this.
                </p>
                <p className='font-large theme-font py-2'>Salesforce CRM Development Services</p>
                <p className='py-2 font-grey'>
                The process of creating and modifying Salesforce's Customer Relationship Management (CRM) platform to suit certain company requirements is referred to as Salesforce CRM Development Services. Salesforce is a cloud-based platform that provides a number of features and tools for managing sales, marketing, customer relations, and other business operations.
                </p>
                <p className='py-2 font-gry'>
                Salesforce customization: Creating unique objects, fields, and workflows in Salesforce to fulfill particular business needs. Salesforce integration: Connecting Salesforce to other programs, such marketing automation, ERP, or e-commerce sites.
                </p>
                
                <p className='py-3 font-large font-bold'>
                Salesforce Consulting
                </p>
                
                <p className='py-1 font-grey'>
Experts in the Salesforce platform, Salesforce consultants have worked with companies to maximize their usage of the platform in order to meet their objectives. They might offer guidance on how to apply and customize Salesforce best practices, assist companies in creating unique applications for the platform, and offer users continuing support and training.
                </p>
                <p className='py-3 font-large font-bold'>
                Salesforce Development
                </p>
                
                <p className='py-1 font-grey'>
                The process of developing and modifying apps for the Salesforce platform is known as Salesforce Development. Businesses may manage their sales, marketing, and customer support operations from a single platform with Salesforce, a cloud-based customer relationship management (CRM) tool.
                </p>
                
                <p className='py-3 font-large font-bold'>
                Salesforce Configuration
                </p>
                
                <p className='py-1 font-grey'>
                The process of configuring a Salesforce instance to suit the unique requirements of a company or organization is referred to as Salesforce configuration. Configuration might involve everything from designing new objects, fields, and page layouts to configuring user accounts and security settings.
                </p>
                
                <p className='py-3 font-large font-bold'>
                Salesforce Customization
                </p>
                
                <p className='py-1 font-grey'>
                Salesforce is a potent platform for managing customer relationships (CRM) that helps companies improve efficiency, optimize workflows, and manage client interactions. Nonetheless, companies frequently need to modify the Salesforce platform to suit their own requirements in order to get the most out of it. The process of modifying the Salesforce platform to suit the particular requirements of a company is known as Salesforce customization.
                </p>
                
                <p className='py-3 font-large font-bold'>
                Salesforce Integration
                </p>
                
                <p className='py-1 font-grey'>
                Businesses can gain a lot from Salesforce integration, such as enhanced productivity, faster processes, better customer insights, and more accurate data. Businesses may automate tedious operations, enhance team collaboration, and generate a unified view of customer data by connecting Salesforce with other software programs.
                </p>
                
                
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Salesforcepage