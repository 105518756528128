import React, { useEffect } from 'react'
import Testingpage from './Testingpage'
import Footer from '../../Footer/Footer'
import Navbar from '../../Home/Navbar'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Testing() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
      <Navbar />
    <div className='pb-14'>
        <Testingpage />
    </div>
    <Icons />
    <Footer />
    </div>
  )
}

export default Testing