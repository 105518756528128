import React from 'react'
import Navbar from '../../Home/Navbar'
import Mediapage from './Mediapage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'

function Media() {
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Mediapage />
        </div>
        <Icons />
        <Footer/>
    </div>
  )
}

export default Media