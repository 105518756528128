import React, { useEffect } from 'react'

import linkedin from "../../assets/images/linkedin.png"
import "./Icons.css"

function Icons() {
    const {pathname} = window.location
  const path = pathname
  useEffect(()=>{
    window.scrollTo(0,0)
  },[path])
  return (
    <div className='fixed bottom-5 right-3 z-50'>
        <div className='icons' >
        <div className='upnav rounded-full'>
                <a href='#' >
                    <svg fill="none" className='rounded-full p-1' stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                         <path  strokeLinecap="round" stroke-linejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"></path>
                    </svg>
                </a>
            </div>
            <div>
                <a href='https://www.linkedin.com/in/wintec-soft-361539211' target="_blank">
                    <img src={linkedin} width="35px" className='py-1 rounded-full' alt="twitter" />
                </a>
            </div>
        </div>
    </div>
  )
}

export default Icons