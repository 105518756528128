import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/vsap1.jpg"
import { Link } from 'react-router-dom'

function Sappage() {
  return (
    <div>
    <div className='flex items-center justify-center bannersap bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>SAP Service </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>SAP Service </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto pt-10'>
            {/* <p className='font-theme text-center font-bold py-3'>SAP Service   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Why Wintecsoft Software Solutions for SAP</p>
                <p className='font-grey py-2'>
                Integrated Business Processes: SAP provides a full range of business applications that may combine into a single system all of the essential business operations, including manufacturing, sales, finance, and procurement. This can increase productivity and simplify processes.
                </p>
                <p className='py-2 font-grey'>
                Scalability: SAP software has a great degree of scalability and can be used by companies of all kinds, from global conglomerates to little startups. An organization may choose to add more modules and capabilities to its SAP deployment as it develops.
                </p>
                <p className='py-2 font-gry'>
Standardization: The business may lower variability and guarantee consistency by utilizing the standardized processes that SAP software offers. This can lessen errors, increase quality, and make it easier to comply with legal obligations.
</p>
                
                <p className='py-3 font-large font-bold'>
                What We Offer
                </p>
                <p className='py-1 font-grey'>
                What We Offer<br />Enterprise Resource Planning (ERP)
<br />Customer Relationship Management (CRM) <br />
Supply Chain Management (SCM) <br />
Business Intelligence and Analytics:<br />
Digital Transformation<br />
Industry-specific solutions
<br />
                </p >
                
                <p className='py-1 font-grey'>
                Organizations may better manage key business activities like accounting, HR, procurement, and production planning with the use of SAP ERP software.                </p>
                <p className='py-3 font-large font-bold'>
                Salesforce Development
                </p>
                
                <p className='py-1 font-grey'>
                Businesses can manage sales, marketing, and customer service interactions and connections with customers by using SAP CRM software.                </p>
                
                <p className='py-1 font-grey'>
                Businesses may manage their supply chain operations, such as logistics, inventory control, and procurement, with the aid of SAP SCM software.                </p>
                
                <p className='py-1 font-grey'>
                SAP provides a number of analytics tools, such as SAP BusinessObjects and SAP Analytics Cloud, to assist companies in analyzing and visualizing their data.
                    </p>
                
                <p className='py-1 font-grey'>
                SAP provides a range of technologies, such as the SAP Leonardo platform for blockchain, machine learning, and Internet of Things (IoT), to assist companies in modernizing their operations for the digital era.
                </p>
                
                
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Sappage