import React from 'react'
import "./Testing.css"
import img from "../../../assets/images/vtest.jpg"
import { Link } from 'react-router-dom'

function Testingpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannertest'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Services</h1>
        <p className='font-white text-center '>Testing & QA</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Testing & QA</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                It is imperative for enterprises to adjust to the ever-changing market and technological advancements by utilizing up-to-date software. An application's quality and development both play a role in its success. Software can be challenging to operate even with basic functionality and intuitive design if there are technical issues. Consequently, in order to guarantee a faultless user experience and seamless operation in order to meet the overall quality elements of the system, it is essential to perform ongoing software testing and application quality assurance.
                </p>
                <p className='py-3 font-bold font-small'>Software Testing & Quality Assurance Services</p>
                <p className='font-grey py-2'>
                As a CMMI Level 4 accredited software development business, WintecSoft Software Development Pvt Ltd provides QA testing services as a crucial component of every project. We assist our clients in achieving their quality assurance objectives by adhering to industry standard software testing procedures. We are able to implement integrated testing methods and best-of-breed solutions since our QA professionals have mastered a variety of software testing services, technologies, tools, platforms, and standards. We use industry-accepted software testing methods and techniques to assist minimize risk and provide successful solutions.
                </p>
                <p className='font-bold font-large py-3'>SERVICES</p>
                <p className='font-grey py-2'>
                Being a seasoned software and QA testing business, we always make sure that the apps we design meet our clients' high expectations for quality. Here are a few of the services we provide:
                </p>
                <p className=' font-small font-bold py-3'>
                Test Advisory Services
                </p>
                <p className='font-small font-bold py-3'>Our Test Advisory Services offers:</p>
                <p className='font-grey p-1'>Test plan/strategy design</p>
                <p className='font-grey '>Test Tools recommendation</p>
                <p className='font-grey '>Evaluation of test assets</p>
                <p className='font-grey '>Planning the test process</p>
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">ios Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Testingpage