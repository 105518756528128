import React from 'react'
import "./Product.css"
import img from "../../../assets/images/vproduct.jpg"
import { Link } from 'react-router-dom'

function ProductBanner() {
  return (
    <div>
    <div className='flex items-center justify-center bannerProduct'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Services</h1>
        <p className='font-white text-center '> Software Product Development And Maintenance</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Software Product Development And Maintenance</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The digital era of today moves quickly, bringing with it new platforms, media, software, hardware, and upgrade opportunities. As a result, it becomes essential to consistently create new software products and maintain existing ones.
                </p>
                <p className='font-grey py-2'>
                WintecSoft Software Development Pvt Ltd, one of his top software product development firms, offers software development, testing, maintenance, and re-engineering services to assist businesses stay competitive in a changing environment. Here I am. We adhere closely to a clearly defined software development process as a CMMI Level 4 organization. To provide our clients with the greatest software solutions, we strictly enforce quality assurance and integrate best practices into our development strategies.
                </p>
                <p className='font-grey py-2'>
                Every project aimed at improving or developing a product adheres to the Software Development Life Cycle (SDLC). This streamlines the procedure and offers you more authority over the creation of new products. Every project involves quality assurance, therefore we make sure that our products function flawlessly. The product development process is not over when it is finished. Software and hardware are regularly updated, and technology is evolving quickly. Therefore, it's crucial to regularly maintain and update apps to stay up to date with developments in order to keep software goods operating. Experienced software developers at WintecSoft software Development Pvt Ltd are taught to manage challenging projects and produce quality work. Our years of knowledge enable us to recognize and forecast emerging trends. Thus, in addition to creating tailored solutions, we also support
                </p>
                <p className='font-grey py-2'>
                Our services ensure that operations are streamlined and running in a defined manner, giving higher value by relieving clients of the burden of adapting systems.
                </p>
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default ProductBanner