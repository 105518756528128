import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img from "../../assets/images/soft.jpg"
import img2 from "../../assets/images/sofff.jpg"
import "./Home.css"



function Banner() {
  return (
   <div className='banner'>
     <Carousel autoPlay={true} infiniteLoop={true} emulateTouch={false} swipeable={false}	showStatus={false}   showThumbs={false} className='banner'	>
                <div className='banner bac-img1'>
                    {/* <img src={img} className=''/> */}
                    <div className="legend flex justify-center items-center">
                        <div className='banner-text  backdrop-blur-sm bg-black/30 rounded-xl p-3'>
                            <h1 className='px-24'>Creative & Innovative WintecSoft Software Development</h1>
                        </div>
                    </div>
                </div>
                <div className='banner bac-img2'>
                    {/* <img src={img2} /> */}
                    <div className="legend flex justify-center items-center">
                    <div className='banner-text backdrop-blur-sm bg-black/30 rounded-xl p-3'>
                    <h1 className='px-24'>Creative & Innovative WintecSoft Software Development</h1>
                        </div>
                    </div>
                </div>
                
            </Carousel>
   </div>
  )
}

export default Banner