import React from 'react'
import "./ITStaffing.css"

function ITBanner() {
  return (
    <div>
    <div className='flex items-center justify-center bannerit'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>IT Staffing</h1>
        <p className='font-white text-center '> This is WintecSoft IT Staffing page</p>
        </div>
    </div>
</div>
  )
}

export default ITBanner