import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/vpy.png"
import { Link } from 'react-router-dom'

function Pythonpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerpython bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>Python App Development </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Python App Development </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Python App Development   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Python is a well-liked high-level programming language with various uses, including data analysis, artificial intelligence, scientific computing, web development, and many more. Python is a well-liked language for both novices and experts due to its ease of use, readability, and simple syntax.
                </p>
                <p className='py-2 font-grey'>
                A sizable and vibrant Python development community has produced a broad library of modules and packages that increase the capability of the language. Additionally, Flask and Django are two well-known web frameworks for Python that speed up and simplify online development.
                </p>
                <p className='font-grey py-2'>
                Writing code in an integrated development environment (IDE) or text editor, testing, and debugging it, and then deploying it to a production environment are the standard steps in Python development. Python can be installed on servers or in the cloud and run on a variety of operating systems, such as Windows, macOS, and Linux.
                </p>
                <p className='font-grey py-2'>
                Python's popularity in data science and AI has grown recently because of its robust machine learning and deep learning packages, such Scikit-learn and TensorFlow, as well as its strong support for mathematical operations and data manipulation.

                </p>
                <p className='py-3 font-bold'>
Our Python development services include:
                </p>
                
                <p className='py-1 font-grey'>Web development</p>
                <p className='font-grey py-1'>
                Data analysis                </p>
                <p className='font-grey py-1'>
                Machine learning
                </p>
                <p className='font-grey py-1'>
                Automation
                </p>
                <p className='font-grey py-1'>
                AI development Mobile app development
               </p>
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Pythonpage