import React from 'react'
import "./About.css"

function BannerAbout() {
  return (
    <div>
        <div className='flex items-center justify-center bannerAbout'>
            <div data-aos="zoom-in " data-aos-duration="1500">
            <h1 className='font-white text-center py-2 font-bold font-large'>About Us</h1>
            <p className='font-white text-center '> This is WintecSoft about page</p>
            </div>
        </div>
    </div>
  )
}

export default BannerAbout