import React from 'react'
import "../../index.css"
import "./Home.css"
import { Link } from 'react-router-dom'

function Services() {
  return (
    <div className='container mx-auto py-24'>
        <p className='py-3 font-small font-bold text-center theme-font '>OUR SERVICES</p>
        <h2 className='p-1 pb-3 font-large font-bold text-center'>Customized service solutions <br/>for your business success</h2>
        <div className='grid grid-cols-6'>
            <div data-aos="fade-right" className='lg:col-span-2 md:col-span-3 col-span-6 m-2 rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac card-bac11 height100 rounded-xl">
  <div class="py-4 ">
  <div class="font-large theme-font flex justify-center p-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 00.374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
</svg>
      
    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Software Product Development And Maintenance</h2>
   
    <p className='font-grey'>Software maintenance is the process of changing, modifying, and updating software to keep up with customer needs.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/service"><button className='py-2 px-4 mt-4 button-border bg-white rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-down" className='lg:col-span-2 md:col-span-3 col-span-6 m-2 rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac card-bac12 height100 rounded-xl">
  <div class="py-6 ">
  <div class="font-large theme-font flex justify-center p-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z" clip-rule="evenodd" />
  <path fill-rule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z" clip-rule="evenodd" />
</svg>

    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Big Data & Analytics</h2>
    <p className='font-grey'>Big data is the term used to describe data sets that are too big or complicated for conventional data-processing application software to handle.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/bigdata"><button className='py-2 px-4 mt-4 button-border bg-white rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-left" className='lg:col-span-2 md:col-span-3 col-span-6 m-2 rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac card-bac13 height100 rounded-xl">
  <div class="py-3 ">
    <div class="font-large theme-font flex justify-center p-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z" />
</svg>
    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Enterprise Solutions</h2>
    <p className='font-grey '>Created to facilitate the exchange of data between different business process areas and associated databases, thereby integrating diverse aspects of an organization's operations.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/enter"><button className='py-2 px-4 mt-4 button-border bg-white rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-right" className='lg:col-span-2 md:col-span-3 col-span-6 m-2 rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac card-bac14 height100 rounded-xl">
  <div class=" py-8  ">
    <div class="font-large theme-font flex justify-center p-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M4.5 9.75a6 6 0 0111.573-2.226 3.75 3.75 0 014.133 4.303A4.5 4.5 0 0118 20.25H6.75a5.25 5.25 0 01-2.23-10.004 6.072 6.072 0 01-.02-.496z" clip-rule="evenodd" />
</svg>

    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Cloud Management Services</h2>
    <p className='font-grey'> The methodical administration of cloud computing services and goods that run on cloud infrastructure.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/cloud"><button className='py-2 px-4 mt-4 button-border bg-white rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-up" className='lg:col-span-2 md:col-span-3 col-span-6 m-2 rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac card-bac15 height100 rounded-xl">
  <div class="py-4 ">
    <div class="font-large theme-font flex justify-center p-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path d="M10.5 18.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" />
  <path fill-rule="evenodd" d="M8.625.75A3.375 3.375 0 005.25 4.125v15.75a3.375 3.375 0 003.375 3.375h6.75a3.375 3.375 0 003.375-3.375V4.125A3.375 3.375 0 0015.375.75h-6.75zM7.5 4.125C7.5 3.504 8.004 3 8.625 3H9.75v.375c0 .621.504 1.125 1.125 1.125h2.25c.621 0 1.125-.504 1.125-1.125V3h1.125c.621 0 1.125.504 1.125 1.125v15.75c0 .621-.504 1.125-1.125 1.125h-6.75A1.125 1.125 0 017.5 19.875V4.125z" clip-rule="evenodd" />
</svg>
    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">Mobile Application Development Company</h2>
    <p className='font-grey'>A mobile app developer designs, develops, and tests applications for mobile devices using programming languages and development expertise.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/mobile"><button className='py-2 px-4 mt-4 button-border bg-white rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
            <div data-aos="fade-left" className='lg:col-span-2 md:col-span-3 col-span-6 m-2 rounded-lg shadow-xl border'>
                <div className='card-service height100'>
                <div className="p-8 card-bac card-bac16 height100 rounded-xl">
  <div class="py-12 ">
    <div class="font-large theme-font flex justify-center p-2">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M3 6a3 3 0 013-3h12a3 3 0 013 3v12a3 3 0 01-3 3H6a3 3 0 01-3-3V6zm14.25 6a.75.75 0 01-.22.53l-2.25 2.25a.75.75 0 11-1.06-1.06L15.44 12l-1.72-1.72a.75.75 0 111.06-1.06l2.25 2.25c.141.14.22.331.22.53zm-10.28-.53a.75.75 0 000 1.06l2.25 2.25a.75.75 0 101.06-1.06L8.56 12l1.72-1.72a.75.75 0 10-1.06-1.06l-2.25 2.25z" clip-rule="evenodd" />
</svg>

    </div>
    <h2 class="text-xl font-bold mb-2 text-center text-gray-800">UI And UX Development</h2>
    <p className='font-grey'> User interface design is known as UI design, and user experience design is known as UX design.</p>
  </div>
  <div className='flex justify-center'>
    <Link to="/uiux"><button className='py-2 px-4 mt-4 button-border bg-white rounded-md '>Read more</button></Link>
  </div>
</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Services