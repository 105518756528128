import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/microsoft.webp"
import { Link } from 'react-router-dom'

function Travelpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannermicro bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>Microsoft Dot Net</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Microsoft Dot Net</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Microsoft Dot Net</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Microsoft.NET is a software development framework that offers a runtime, a set of libraries, and a programming style for creating and executing software programs. Since its first release in 2002, it has grown in popularity as a tool for developing a variety of applications, such as online, mobile, desktop, and gaming apps.
                </p>
                <p className='py-2 font-grey'>
                The.NET framework is intended to function as a cross-platform application on a variety of operating systems, such as Windows, Linux, and macOS. It offers compatibility across various programming languages and comes with several, including as C#, Visual Basic, and F#.
                </p>
                <p className='font-grey py-2'>
                The Common Language Runtime (CLR), which is part of the.NET framework, offers automatic garbage collection, memory management, and other capabilities that facilitate the writing of dependable and safe programs. In addition, the framework comes with a sizable library of pre-built components that may be used to rapidly construct apps without requiring the writing of any code.
                </p>
                <p className='font-grey py-2'>
                All things considered, the.NET framework is strong and adaptable and has been utilized to create a variety of applications in numerous industries. With new releases and updates, it keeps becoming better and better, which makes it a dependable option for software development.
                </p>
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Travelpage