import React from 'react'
import Navbar from '../../Home/Navbar'
import Travelpage from './Travelpage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'

function Travel() {
  return (
    <div>
        <Navbar />
        <div>
            <Travelpage/>
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Travel