import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import Pythonpage from './Pythonpage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Python() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Pythonpage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Python