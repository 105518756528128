import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import Educationpage from './Educationpage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Education() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Educationpage />
        </div>
        <Icons />
        <Footer />
    </div>
  )
}

export default Education