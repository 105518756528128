import React, { useEffect } from 'react'
import Navbar from '../Home/Navbar'
import Contactuspage from './Contactuspage'
import Footer from '../Footer/Footer'
import Icons from '../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

const ContactUs = () => {
  useEffect(()=>{
    Aos.init()
  })
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Contactuspage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default ContactUs