import React from 'react'
import "../../index.css"
import "./About.css"

function AboutPeopel() {
  return (
    <div className='container mx-auto'>
        <div data-aos="fade-right" data-aos-anchor-placement="top-center" className='grid grid-cols-6'>
      <div className='col-span-1'></div>
      <div className='col-span-6 p-2 sm:col-span-4'>
      <div className='container mx-auto aboutwin'>
            <p className='font-large theme-font text-center pb-3 font-bold'>People & Process</p>
            <div className='flex justify-center winlist'>
                <div>
                    <p className='font-large'>People</p>
                    <p className='font-grey py-2'> Since people are the most valuable resource for any business that provides services, IT companies place a high value on them. This is especially true for businesses that provide outsourcing services for custom software development.</p>
                    <p className='font-grey py-2'>The team at WintecSoft Software Development Pvt Ltd is made up of more than 810 IT experts with a wealth of global project design, development, and management experience. Our seasoned team is made up of professionals that are qualified and focused on their goals. We attain enduring customer satisfaction by integrating the finest personnel, procedures, and technology. The best way to describe Team United Alliance Technology LLC is as young, driven, lovable, and ambitious. However, its attributes come from workers who are driven to succeed, produce high-caliber work on schedule, and add value for clients while having a good time. since their ability and dedication are the foundation of their success. </p>
                    <p className='font-grey py-2'>People are the most valuable resource in the information technology sector, and this is something we never forget. Our staff members meet our clients' needs. The following is what we anticipate from our staff: </p>
                    <div className='py-3'>
                    <ul className='font-grey rounded-xl backdrop-blur-sm bg-white/30'>
                        <div>
                            <p className='font-large font-bold theme-font'>People</p>
                            <p className='font-large '>Accountability</p>
                            <p className='py-2 font-grey'>
                            Continue to take responsibility for your individual and teamwork.                            </p>
                        </div>
                        <div>
                            <p className='font-large '>Guide</p>
                            <p className='py-2 font-grey'>
                            Leadership is a crucial component of both individual and business success. 
                            </p>   
                        </div>
                        <div>
                            <p className='font-large '>Honesty and Trust</p>
                            <p className='py-2 font-grey'>
                            Openness to innovation by asking, contradicting and proposing in a disciplined environment                            </p>
                        </div>
                        <div>
                            <p className='font-large '>Openness</p>
                            <p className='py-2 font-grey'>
                            Openness to innovation by asking, contradicting and proposing in a disciplined environment                           </p>
                        </div>
                        <div>
                            <p className='font-large '>Teamwork</p>
                            <p className='py-2 font-grey'>
                            Respect opinions, share ideas and information, collaborate and support each other to achieve goals
                            </p>   
                        </div>
                        <div>
                            <p className='font-large '>Communication   </p>
                            <p className='py-2 font-grey'>
                            Exchange ideas, listen to customers and colleagues, and accept constructive criticism
                            </p>   
                        </div>
                        <div>
                            <p className='font-large '>Excellence</p>
                            <p className='py-2 font-grey'>
                            Constantly improve ourselves to achieve excellence 
                            </p>   
                        </div>
                        <p>Teamwork is the driving force for achieving extraordinary results.</p>
                       
                    </ul>
                    </div>
                    <p className='font-grey py-2'>A single location to get everything your company needs, including software development, digital marketing, web and mobile development, and UI/UX design.</p>
                    <p className='font-grey py-2'>As well as cutting-edge innovations like chatbots, cloud computing, IoT, big data, artificial intelligence, and Python..</p>
                </div>
            </div>
    </div>
      </div>
      <div className='col-span-1'>

      </div>
    </div>
    <div data-aos="fade-left" data-aos-anchor-placement="top-center" className='py-4'>
        <p className='py-4 font-large font-bold theme-font text-center '>Process</p>
        <div>
            <p className='font-large fon '>Software Development Process at WintecSoft software Development Pvt Ltd</p>
            <p className='font-grey py-2'>
            Software development firm Axtas Software Development Pvt Ltd holds Microsoft Gold and CMMi Level 4 certifications. Our close working relationship with our customers and the process we follow set our software outsourcing model apart from other "software factories". United Alliance Technology LLC takes a creative approach by paying close attention to the needs and difficulties that our clients face and then coming up with unique solutions that draw from our knowledge and experience.
            </p>
            <p className='font-grey py-2'>
            Our onshore/offshore software development model is interactive and based on the idea of a global team, allowing team members or the customer's IT staff to work collaboratively. In contrast, other software companies rarely collaborate with their customers because their specifications are "thrown over the wall". End users that you designate to oversee scope modifications, evaluate prototypes, and specify requirements.The offshore software development team at WintecSoft software Development Pvt Ltd is in charge of completing projects on time and within budget while upholding quality standards.            </p>
        </div>
        <div className='pt-4'>
            <p className='font-large font-bold '>WintecSoft software Development Pvt Ltd</p>
            
            <p className='font-grey py-2'>
            When an IT service provider contracts with WintecSoft software Development Pvt Ltd to develop software specifically for a customer, the IT service provider serves as a liaison between the client and WintecSoft software Development Pvt Ltd.
           </p>
        </div>
    </div>
    </div>
  )
}

export default AboutPeopel