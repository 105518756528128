import React from 'react'
import img from "../../assets/images/itstaff.jpg"
import "../../index.css"

function ITabout() {
  return (
    <div className='container mx-auto '>
        <div className='grid grid-cols-5'>
            <div data-aos-duration="1000" data-aos="fade-right" className='sm:col-span-3 col-span-5 flex self-center'>
                <div>
                <h1 className='text-xl theme-font font-semibold'>IT STAFFING SERVICES</h1>
                <h1 className='font-black text-3xl font-bold pt-2 pb-3'>
                The Best IT Solution With 5 Years of Experience
                </h1>
                <p className='font-grey p-3'>The process of finding and employing competent IT specialists for either temporary or permanent roles within a company is referred to as IT staffing services. These services, which can include a variety of job functions like software development, network administration, and technical support, are frequently offered by staffing companies that specialize in the IT sector. </p>
                <p className='font-grey p-3'>Using IT staffing services can help your company quickly and effectively fill open positions, which is one of its main advantages. Working with a staffing agency gives you access to a pool of qualified applicants who have already undergone screening and are prepared to begin working on your projects immediately.</p>
                <div className='flex justify-between mr-24 py-4'>
                    <div >
                    <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>Experienced HR Recruiters</p>
                        </div>
                        <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>Wide Talent Pool for IT</p>
                        </div>
                        <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>Servicing more than 150+ Clients Global</p>
                        </div>
                        <div className='flex self-center'>
                       <div className='theme-font p-2'>
                         <svg className=' w-6 h-6' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg>
                       </div>
                            <p className='p-2 text-lg'>100% Guaranteed Statutory & Legal Compliant Solution</p>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
                </div>
            </div>
            <div data-aos-duration="1000" data-aos="fade-left" className='flex justify-center sm:col-span-2 col-span-5 flex self-center'>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
            </div>
        </div>
        <div className='px-1'>
        <div data-aos-duration="1000" data-aos="fade-up">
          <h1 className='font-large theme-font font-bold pt-3'> Temparorry staffing </h1>
          <p className='font-grey py-2'>
            
Temporary staffing refers to the practice of hiring employees for a specific period to meet short-term business needs. Organizations often engage in temporary staffing to address fluctuations in workload, special projects, or to cover employee absences. Temporary staff, also known as contingent workers or temps, are typically employed through staffing agencies.

          </p>
          <p className='font-grey py-2'>
          This flexible employment arrangement provides businesses with the agility to scale their workforce up or down based on demand, without the long-term commitment associated with permanent hires. Temporary staffing is prevalent across various industries, allowing companies to access skilled individuals for specific tasks while maintaining adaptability in their workforce structure.
          </p>
        </div>
        <div data-aos-duration="1000" data-aos="fade-up">
          <h1 className='font-large theme-font font-bold pt-3'> Permanent Placements </h1>
          <p className='font-grey py-2'>
            
Permanent placement, often referred to as direct hiring, is a crucial aspect of IT staffing that involves the recruitment and selection of candidates for long-term employment within an organization. In the context of IT staffing, permanent placement plays a pivotal role in building a stable and skilled workforce to meet the organization's ongoing needs.

          </p>
          <p className='font-grey py-2'>
          In essence, permanent placement in IT staffing is a strategic and comprehensive process focused on identifying and securing talented individuals for enduring roles within an organization. This ensures that the organization builds a robust, skilled, and dedicated IT team to support its growth and long-term success.
          </p>
        </div>
        <div data-aos-duration="1000" data-aos="fade-up">
          <h1 className='font-large theme-font font-bold pt-3'> Consulting Services </h1>
          <p className='font-grey py-2'>
          Consulting services in IT staffing encompass expert guidance and support provided by seasoned professionals to assist organizations in navigating complex technology and workforce challenges. These services involve strategic consultation on various aspects of IT staffing, including talent acquisition, skill assessment, project management, and workforce optimization. IT staffing consulting aims to align the organization's technological needs with the right talent, ensuring efficiency, innovation, and adaptability in the rapidly evolving IT landscape. Consulting services act as a valuable resource for companies seeking tailored solutions to enhance their IT capabilities, streamline processes, and achieve strategic objectives.
          </p>
        </div>
        <div data-aos-duration="1000" data-aos="fade-up">
          <h1 className='font-large theme-font font-bold pt-3'> Candidate Sourcing </h1>
          <p className='font-grey py-2'>
          User Candidate Sourcing in IT staffing involves leveraging various methods and platforms to identify and engage potential candidates for job positions within an organization. This user-centric approach focuses on enhancing the candidate experience throughout the sourcing process. By utilizing online platforms, social media, networking events, and other channels, IT staffing professionals aim to connect with and attract qualified individuals, ensuring a continuous pool of talent to meet organizational needs.          
          </p>
        </div>
        <div data-aos-duration="1000" data-aos="fade-up">
          <h1 className='font-large theme-font font-bold pt-3'> Screening and Evalution </h1>
          <p className='font-grey py-2'>
          User screening and evaluation in IT staffing is a meticulous process designed to assess the qualifications, skills, and suitability of potential candidates for specific job roles within an organization. This user-focused approach involves systematically reviewing resumes, conducting interviews, and evaluating technical proficiency to ensure a precise match between the candidate's abilities and the job requirements. By employing standardized assessments, skill tests, and behavioral interviews, IT staffing professionals aim to identify candidates who not only possess the necessary technical expertise but also align with the company's values and culture.       
          </p>
           </div>
           <div data-aos-duration="1000" data-aos="fade-up">
          <h1 className='font-large theme-font font-bold pt-3'>Job Matching</h1>
          <p className='font-grey py-2'>
          User job matching in IT staffing involves the meticulous process of aligning a candidate's skills, qualifications, and preferences with the requirements of a specific job role. This user-centric approach focuses on finding the most suitable match between the candidate and the job, ensuring a strong fit for both technical competencies and cultural alignment. Through the use of advanced algorithms, data analysis, and personalized assessments, IT staffing professionals aim to optimize the job matching process, ultimately leading to successful and satisfying employment outcomes for both the candidate and the hiring organization. The goal is to create synergies that benefit all parties involved, contributing to long-term success and productivity in the IT workforce.          </p>
           </div>
        </div>
    </div>
  )
}

export default ITabout