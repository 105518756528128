import React, { useEffect } from 'react'
import ITBanner from './ITBanner'
import Navbar from '../Home/Navbar'
import ITabout from './ITabout'
import Footer from '../Footer/Footer'
import Icons from '../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"
function ITStaffing() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
      <Navbar />
        <ITBanner />
        <div className='py-24'>
          <ITabout />
          <Icons />
        </div>
        <Footer />
    </div>
  )
}

export default ITStaffing