import React from 'react'
import Navbar from '../../Home/Navbar'
import Retailingpage from './Retaililngpage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'

function Retailing() {
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Retailingpage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Retailing