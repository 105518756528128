import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/shiping.jpg"
import { Link } from 'react-router-dom'

function ShipingPage() {
  return (
    <div>
    <div className='flex items-center justify-center bannership bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Shiping, Transportation & Logistics</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Shiping, Transportation & Logistics</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Shiping, Transportation & Logistics</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Moving people and things from one location to another is at the heart of the shipping, transportation, and logistics sectors.

Air, road, rail, and ocean transportation are just a few of the more common ways that people and things are moved.
                </p>
                <p className='py-2 font-grey'>
                In order to satisfy consumer demands, logistics refers to the control of the movement of products, information, and other resources between the point of origin and the place of consumption. This can include managing inventory and supply chain procedures in addition to organizing, arranging, and carrying out the transportation, warehousing, and distribution of commodities.
                </p>
                <p className='font-grey py-2'>
                Software for point of sale (POS): This kind of program is used to handle payments, keep track of inventories, and manage transactions in real time. Additionally, reports may be generated and sales data can be analyzed with it.
                </p>
                <p className='font-grey py-2'>
                Effective shipping, transportation, and logistics are critical components of many businesses, enabling them to move products efficiently, reliably, and cost-effectively. These industries also play a significant role in global trade, connecting producers with consumers around the world.
                </p>
                
                    <p className='font-large font-bold py-3'>Here are some of the key areas of development:</p>
                <p className='font-grey py-2'>
                Over the past few years, the shipping, transportation, and logistics sector has seen substantial growth, driven by changes in consumer behavior and technology improvements.
Digitization Digital technologies are being quickly adopted by the sector in order to improve productivity, save costs, and streamline procedures. This covers modern technology including the Internet of Things (IoT), blockchain, and artificial intelligence.
                </p>
                <p className='font-grey py-2'>
                Sustainability: Reducing the negative effects of shipping and transportation on the environment is gaining more attention. This has prompted efforts to lower emissions and advance sustainability along the supply chain, as well as the development of alternative fuels like biofuels and electric cars.
                </p>
                <p className='font-grey py-2'>
                E-commerce: As the need for quick and convenient delivery grows, businesses are investing in novel delivery techniques like drones and autonomous cars. This has had a tremendous impact on the industry.
                </p>
                <p className='py-2 font-grey'>
                Globalization: As businesses expand internationally and invest in new technologies to better manage worldwide supply chains, the sector has grown more and more globalized.
                </p>
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default ShipingPage