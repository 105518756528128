import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/retail.jpg"
import { Link } from 'react-router-dom'

function Retailingpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerretail bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Retailing</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Retailing</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Retailing</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The use of software to manage different retail processes, such as inventory management, sales monitoring, customer relationship management, and financial reporting, is referred to as retail software services. The purpose of these software programs is to assist merchants in optimizing their processes and coming to more informed business decisions.
                </p>
                <p className='py-2 font-grey'>
                Some of the major challenges faced by Retail industry are:
                </p>
                <p className='font-grey py-2'>
                Software for point of sale (POS): This kind of program is used to handle payments, keep track of inventories, and manage transactions in real time. Additionally, reports may be generated and sales data can be analyzed with it.
                </p>
                <p className='font-grey py-2'>
                Software for inventory management: This kind of program keeps track of stock levels, orders more when needed, and maintains vendor relationships.
                </p>
                <p className='font-grey py-2'>
                Software for managing customer relationships (CRM): By monitoring user behavior and preferences, CRM software helps enhance customer retention and handle customer interactions.
                </p>
                    <p className='font-large font-bold py-3'>Experience In Retail Software Services</p>
                <p className='font-grey py-2'>
                A variety of software products and services that assist retailers in running their businesses more profitably are referred to as retail software services. Point-of-sale (POS) systems, inventory control software, CRM tools, and e-commerce platforms are a few examples of these solutions.
                </p>
                <p className='font-grey py-2'>
                Retailers may increase customer satisfaction, cut expenses, and streamline operations with the aid of retail software services. Retailers, for instance, can manage sales transactions, monitor inventory levels, and provide reports with the use of a POS system. Retailers may increase the accuracy of their forecasts, streamline their supply chain, and minimize stockouts by implementing an inventory management system. Retailers may tailor their marketing campaigns and gain a deeper understanding of their customers by utilizing a CRM solution.
                </p>
                <p className='font-grey py-2'>
                All things considered, merchants aiming to enhance their business and maintain their competitiveness in the quickly evolving retail market of today may find that investing in retail software services is worthwhile.
Accounting software is used to track costs and revenues, create financial reports, and handle financial transactions.
                </p>
                <p className='py-2 font-grey'>E-commerce software: This type of software is used to manage online sales, including website design, order processing, and payment processing.</p>

              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">ios Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Retailingpage