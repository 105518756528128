import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/vnode.png"
import { Link } from 'react-router-dom'

function Nodepage() {
  return (
    <div>
    <div className='flex items-center justify-center bannernode bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>Node Js App Develpment </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Node Js App Develpment </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Node Js App Develpment   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                A cross-platform, open-source back-end JavaScript runtime environment called Node.js is made to help developers create scalable and quick network applications. It is constructed upon the V8 JavaScript engine, which powers Google Chrome.
                </p>
                <p className='py-2 font-grey'>
                With the help of Node.js' non-blocking I/O API, programmers may quickly and effectively build network applications. Additionally, it comes with a large number of built-in modules, including HTTP, HTTPS, and Net, which simplify the development of online applications.
                </p>
                
                <p className='py-3 font-bold'>
                Some of the key benefits of using Node.js for web development include:
                </p>
                
                <p className='py-1 font-grey'>
                Speed: Node.js is known for its fast execution speed due to its non-blocking I/O model.
                </p>
                <p className='font-grey py-1'>
                Scalability: Node.js can handle a large number of concurrent connections, making it a great choice for building scalable applications.
                </p>
                <p className='font-grey py-1'>
                Cross-platform: Node.js runs on multiple platforms such as Windows, Linux, and Mac OS, making it a versatile choice for developers.
                </p>
                <p className='font-grey py-1'>
                
Large community: Node.js has a large and active community of developers, making it easy to find support and resources.
                </p>
                <p className='py-3 font-bold'> Our Node Js development services include:</p>
                <p className='font-grey py-1'>
                API Development
               </p>
               <p className='font-grey py-1'>
               Real-Time Applications
               </p>
               <p className='font-grey py-1'>
               Custom Web Applications  
               </p>
               <p className='font-grey py-1'>
               Cloud Integration
               </p>
               <p className='font-grey py-1'>
               Microservices Development
               </p>
               <p className='font-grey py-1'>
               Cross-Platform Development
               </p>
               <p className='font-grey py-1'>
               Consulting and Training
               </p>
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Nodepage