import React, { useEffect } from 'react'
import Navbar from './Navbar'
import Banner from './Banner'
import Counter from '../Counter/Counter'
import About from './About'
import Choose from './Choose'
import Services from './Services'
import Industries from './Industries'
import Footer from '../Footer/Footer'
import Icons from '../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Home() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <Banner />
        <Counter />
        <About />
        <Choose />
        <Services />
        <Industries />
        <Icons />
        <Footer />
    </div>
  )
}

export default Home