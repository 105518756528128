import { Fragment, useEffect, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import img from "../../assets/images/wintech.png"
import { ChevronDownIcon} from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import "./Home.css"
import verify from "../../assets/images/verify.png"
import linkdin from "../../assets/images/linkedin.png"

const products = [
    { name: 'Product Development & Maintenance',  href: '/service' },
    { name: 'Big Data Analytics',  href: '/bigdata' },
    { name: 'Enterprise Solutions',  href: '/enter' },
    { name: 'Cloud Services',  href: '/cloud' },
    { name: 'Mobile Apps', href: '/mobile' },
    { name: 'Testing & QA', href: '/testing' },
    { name: 'UI & UX Development', href: '/uiux' },
  ]
  
  const industries = [
    { name: 'BFSI',  href: '/bsfi' },
    { name: 'Education',  href: '/education' },
    { name: 'Energy & Utilities',  href: '/energy' },
    { name: 'Health Care',  href: '/health' },
    { name: 'Media & Entertainment ',  href: '/media' },
    { name: 'Oil & Gas mining', href: '/oil' },
    { name: 'Retailing', href: '/retail' },
    { name: 'Shiping,Transportation & Logistics', href: '/shiping' },
    { name: 'Travel & Hospitality', href: '/travel' },

  ]
  const technologies = [
    { name: 'Microsoft.net',  href: '/microsoft' },
    { name: 'Java',  href: '/java' },
    { name: 'Php',  href: '/php' },
    { name: 'IOS',  href: '/ios' },
    { name: 'Android', href: '/android' },
    { name: 'Python',  href: '/python' },
    { name: 'Node js',  href: '/node' },
    { name: 'Oracle',  href: '/oracle' },
    { name: 'Salesforce',  href: '/salesforce' },
    { name: 'Data Science',  href: '/datascience' },
    { name: 'SAP Service',  href: '/sap' },
    { name: 'Autocad Designing',  href: '/autocad' }
  ]
const contact = [
  { name: 'Contact Us', href: '/contact' },
  { name: 'Our Location', href: '/location' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = ()=> {
  const [nav, setnav] = useState("/")
  const [tech, setTech] = useState(false)
  const {pathname}=window.location
  const path = pathname
 const scroll =()=>{
    if(window.scrollY >= 80){
      setTech(true)
    }else{
      setTech(false)
    }
  }
  window.addEventListener("scroll",scroll)
  useEffect(()=>{
      setnav(path)
  },[path])
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

console.log(tech)
  return (
    <header className="head-nav fixed top-0 left-0 right-0 z-40 nav-color " >
    <div className={`border-bottom ${tech? "closenav":"head-top"}`}>
      <div className='container mx-auto flex justify-end items-center	text-sm py-1	'>
      <span className='opacity-90 theme-sec text mx-4  hidden md:block	'>hr@wintecsoft.net</span>
      <div>
          <a href='https://www.linkedin.com/in/wintec-soft-361539211' target='_blank'><img src={linkdin} className='img-fluid linkdin mx-2' /></a>
      </div>
      <img src={verify} className='h-5 mx-3' />
      </div>
      
      

    </div>
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a  className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <Link to="/"><img className="h-14 w-auto " src={img} alt="" /></Link>
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6 font-white menubar" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-8">
        <Link to="/"  className={`text-sm nav-label  font-semibold leading-6 text-gray-900 ${nav==="/"? " active-nav":""}`}>
            Home
          </Link>
          <Link to="/about"  className={`text-sm nav-label  font-semibold leading-6 text-gray-900 ${nav==="/about"? "active-nav":""}`}>
            About
          </Link>
          <Link  to="/it" className={`text-sm nav-label  font-semibold leading-6 text-gray-900 ${nav==="/it"? "active-nav":""}`}>
            IT Staffing
          </Link>
          <Link to="/h1b" className={`text-sm nav-label  font-semibold leading-6 text-gray-900 ${nav==="/h1b"? "active-nav":""}`}>
            H1B application
          </Link>
         
          <Popover className="relative">
            <Popover.Button className={`flex nav-label  items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${nav==="/service" || nav==="/bigdata" || nav==="/enter" || nav==="/cloud" || nav==="/mobile" || nav==="/testing" || nav==="/uiux" ? "active-nav":""}`}>
              Services
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen  max-w-xs overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                    >
                      
                      <div className="flex-auto">
                        <Link to={item.href} nav-label  className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className={`flex nav-label  items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${nav==="/bsfi" || nav==="/education" || nav==="/health" || nav==="/media" || nav==="/oil" || nav==="/retail" || nav==="/shiping" || nav==="/travel" ? "active-nav":""} `}>
              Industries
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-xs overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {industries.map((item) => (
                    <div
                      key={item.name}
                      className="group relative  flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                    >
                      
                      <div className="flex-auto">
                        <Link to={item.href} className="block nav-label1  font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className={`flex items-center nav-label  gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${nav==="/microsoft" || nav==="/java" || nav==="/php" || nav==="/ios" || nav==="/android" || nav==="/python" || nav==="/node" || nav==="/oracle" || nav==="/salesforce" || nav==="/datascience" || nav==="/sap" || nav==="/autocad" ? "active-nav":""}`}>
              Technologies
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3  max-w-xs overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4 techlist">
                  {technologies.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex  items-center gap-x-6 rounded-lg py-2 px-1 text-sm leading-6 hover:bg-gray-50"
                    >
                      
                      <div className="flex-auto">
                        <Link to={item.href} className="block nav-label1  font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button className={`flex items-center gap-x-1 nav-label  text-sm font-semibold leading-6 text-gray-900 ${nav==="/contact" ? " active-nav":""} ${nav==="/location" ? " active-nav":""}`}>
              Contact
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 top-full z-10 mt-3 w-screen  max-w-xs overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {contact.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-2 text-sm leading-6 hover:bg-gray-50"
                    >
                      
                      <div className="flex-auto">
                        <Link to={item.href} className="block nav-label1  font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        
        </Popover.Group>
        
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed z-50 inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a  className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src={img}
                alt="img"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6 " aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
              <Link to="/" className={` monile-nav -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${nav==="/"? " active-mnav":""}`}
                >
                  Home
                </Link>
                <Link to="/about"
                  
                  className={`monile-nav -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${nav==="/about"? "active-mnav":""}`}
                >
                  About Us
                </Link>
                <Link to="/it"
                  
                  className={`monile-nav -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${nav==="/it"? "active-mnav":""}`}
                >
                  IT Staffing
                </Link>
                <Link
                  to="/h1b"
                  className={`monile-nav -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${nav==="/h1b"? "active-mnav":""}`}
                >
                  H1B application
                </Link>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className={` monile-nav flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${nav==="/service" || nav==="/bigdata" || nav==="/enter" || nav==="/cloud" || nav==="/mobile" || nav==="/testing" || nav==="/uiux" ? "active-mnav":""}`}>
                      Services
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {products.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block monile-nav rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                           <Link to={item.href}> {item.name}</Link>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className={`monile-nav flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${nav==="/bsfi" || nav==="/education" || nav==="/health" || nav==="/media" || nav==="/oil" || nav==="/retail" || nav==="/shiping" || nav==="/travel" ? "active-mnav":""}`}>
                        Industries
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {industries.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block monile-nav rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                             <Link to={item.href}> {item.name}</Link>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className={`flex monile-nav w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50  ${nav==="/microsoft" || nav==="/java" || nav==="/php" || nav==="/ios" || nav==="/android" || nav==="/python" || nav==="/node" || nav==="/oracle" || nav==="/salesforce" || nav==="/datascience" || nav==="/sap" || nav==="/autocad" ? "active-mnav":""}`}>
                        Technologies
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {technologies.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block monile-nav rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                             <Link to={item.href}> {item.name}</Link>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className={`flex monile-nav w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 ${nav==="/contact" || nav==="/location" ? "active-mnav":""}`}>
                        Contact
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {contact.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block monile-nav rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            <Link to={item.href}> {item.name}</Link>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                
                
              </div>
              
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
export default Navbar