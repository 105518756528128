import React from 'react'
import "./Enterprice.css"
import img from "../../../assets/images/venter.webp"
import { Link } from 'react-router-dom'

function Enterpricepage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerenter'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Services</h1>
        <p className='font-white text-center '>Enterprise Solutions</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-doen">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Enterprise Solutions</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                There are several different operational and business challenges facing the energy and utility services sector. expanding commodity prices, fierce competition for reserves, uneven demand, expanding consumer and regulatory demands, and—most importantly—the requirement for new technology provide challenges for the majority of businesses worldwide. Now is the moment.                </p>
                <p className='font-grey py-2'>
                WintecSoft Software Development Pvt Ltd employs services including corporate mobility, big data and analytics, cloud services, and bespoke software development to help businesses face new challenges, streamline operations, and cut expenses in order to help them overcome the aforementioned difficulties.                </p>
                <p className='theme-font font-small py-3'>
                Experience with energy management software                </p>
                <p className='font-grey py-2'>
                Companies in the energy and utility sectors benefit from ongoing innovation and increased operational effectiveness. Intelligent and efficient software solutions that can save operational costs, promptly address issues, and optimize the utilization of renewable energy sources are always in demand.                </p>
                <p className='font-grey py-2'>
                With years of experience and knowledge in software development, we have created unique solutions for clients in the utilities and energy sectors. Numerous commercial advantages, like high fault tolerance, low downtime, and less reliance on and interaction from humans, have been made possible by these applications. Reduced energy use and carbon footprint were two further environmental advantages of these energy management software programs.
                </p>
                <p className='font-grey py-2'>Below are highlights of our projects implemented for energy and utilities companies.</p>
                <p className='font-grey '>Process and display data in powerful 2D and 3D charts for detailed analysis</p>
                <p className='font-grey '>Compare multiple scenarios within a project and perform "what if" analysis</p>
                <p className='font-grey '>Accurately perform complex and tedious calculations such as surface tension, pressure, viscosity and gas heat capacity</p>
                <p className='font-grey '>Ability to communicate with a device or equipment</p>
                <p className='font-grey '>Equipment maintenance facility</p>
                <p className='font-grey'>Remote management and control of devices</p>
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Enterpricepage