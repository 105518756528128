import React from 'react'
import Navbar from '../Home/Navbar'
import H1page from './H1page'
import Icons from "../Icons/Icons"
import Footer from '../Footer/Footer'

function H1() {
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <H1page />
        </div>
        <Icons />
        <Footer />
    </div>
  )
}

export default H1