import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/datascience.jpg"
import { Link } from 'react-router-dom'

function datapage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerdata bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>Data Science </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Data Science </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto pt-10'>
            {/* <p className='font-theme text-center font-bold py-3'>Data Science   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>World-class data scientists and machine learning engineers</p>
                <p className='font-grey py-2'>
                Experts in machine learning and data science usually possess a solid background in programming languages like SQL, R, and Python. Additionally, they are conversant with well-known machine learning frameworks and libraries like Scikit-learn, PyTorch, and TensorFlow.
                </p>
                <p className='py-2 font-grey'>
                These experts are able to create and apply machine learning models that improve workflow, address intricate business issues, and offer insights that help guide defensible choices. They are also adept at data engineering, which is the process of creating and constructing data pipelines for the purpose of gathering, organizing, and processing massive amounts of data.
                </p>
                <p className='py-2 font-gry'>
                Experts in machine learning and data science usually possess a solid background in programming languages like SQL, R, and Python. Additionally, they are conversant with well-known machine learning frameworks and libraries like Scikit-learn, PyTorch, and TensorFlow.
                </p>
                
                <p className='py-2 font-grey'>
                Last but not least, exceptional data scientists and machine learning engineers are enthusiastic about what they do, continuously learning new things, and keeping up with the most recent methods and trends in the industry.
                </p>
                
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default datapage