import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/voracle.png"
import { Link } from 'react-router-dom'

function Oraclepage() {
  return (
    <div>
    <div className='flex items-center justify-center banneroracle bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>Oracle </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Oracle </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Oracle   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                Oracle is a global technology firm with a focus on creating and promoting cloud services, business software apps, and database management systems as well as other corporate software solutions. With its headquarters located in Redwood City, California, the company was founded in 1977 by Ed Oates, Bob Miner, and Larry Ellison.
                </p>
                <p className='font-large theme-font py-2'>Development</p>
                <p className='py-2 font-grey'>
                The relational database management system, or Oracle Database, is one of the company's main offerings. It is extensively utilized in business settings to store and handle massive amounts of data. Additionally, the company provides a large selection of enterprise applications, such as supply chain management (SCM), customer relationship management (CRM), and enterprise resource planning (ERP) software.
                </p>
                <p className='py-2 font-gry'>
                Oracle has been concentrating more and more on cloud computing services in the past few years, such as software as a service (SaaS), platform as a service (PaaS), and infrastructure as a service (IaaS). The Oracle Cloud Infrastructure, Oracle Autonomous Database, and Oracle Fusion Applications are among the cloud services provided by the firm.
                </p>
                
                <p className='py-3 font-bold'>
                Oracle Services includes:
                </p>
                
                <p className='py-1 font-grey'>
                Database Management
                </p>
                <p className='font-grey py-1'>
                Cloud Infrastructure
                </p>
                <p className='font-grey py-1'>
                Application Development
                </p>
                <p className='font-grey py-1'>                
                Business Analytics
                </p>
                <p className='font-grey py-1'>                
                Enterprise Resource Planning (ERP)
                </p>
                <p className='py-3 font-large font-bold'>Implementation</p>
                <p className='font-grey py-2'>
                Planning meticulously, paying close attention to details, and coordinating with multiple stakeholders are necessary while developing an Oracle system. Although installing an Oracle system can be a complicated procedure that takes several months to finish, the advantages can be substantial, including higher competitiveness, better decision-making, and increased productivity.
                </p>
                <p className='py-3 font-large font-bold'>
                Capabilities
                </p>
               <p className='font-grey py-2'>
               Database management: A variety of data kinds, including structured, semi-structured, and unstructured data, are supported by Oracle's database management system. Either on-site or cloud deployment is possible for the database.
               </p>
               <p className='font-grey py-2'>
               Cloud Services: Software as a service (SaaS), platform as a service (PaaS), and infrastructure as a service (IaaS) are all included in Oracle's extensive array of cloud services. High-performance computing capability and scalability are features of the Oracle Cloud infrastructure.
                </p>
               <p className='font-grey py-2'>
               Enterprise Software: Oracle provides a number of enterprise software options, such as supply chain management (SCM), human capital management (HCM), enterprise resource planning (ERP), and customer relationship management (CRM).
               </p>
               <p className='font-grey py-2'>
               Artificial Intelligence and Machine Learning: To analyze data, forecast outcomes, and automate business procedures, Oracle provides a range of AI and ML solutions.
               </p>
               <p className='font-grey py-2'>
               Security: Oracle offers a number of security solutions that can assist in defending infrastructure, data, and apps against online attacks. Cloud security, identity and access management, and database security are some of these options.
               </p>
               <p className='font-grey py-2'>
               Analytics: To help businesses make sense of their data, Oracle provides a range of analytics tools. Data mining, data visualization, and business intelligence are some of these solutions.
vertical sector-specific services
</p>
               <p className='font-grey py-2'>
               Mobile Development: To create mobile applications for the iOS and Android platforms, Oracle offers a variety of tools and technologies.
               </p> 
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Oraclepage