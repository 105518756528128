import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/voil.webp"
import { Link } from 'react-router-dom'

function Oilpage() {
  return (
    <div>
    <div className='flex items-center justify-center banneroil bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Oil Gas</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Oil Gas</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Oil Gas</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                For more than a century, the oil and gas sector has been a major contributor to global economic growth by serving as the main energy source for heating, transportation, and the production of electricity. The industry has gone through various stages of growth and development in addition to times of upheaval and decline. An outline of the growth of the oil and gas sector is provided below:
                </p>
                <p className='font-grey py-2'>
                Early Development: The discovery of oil in Pennsylvania, USA, in the middle of the 19th century marked the beginning of the modern oil and gas business. Over the following few decades, the business expanded quickly as drilling and refining technologies advanced.
                </p>
                <p className='font-grey py-2'>
                Globalization and business Expansion: The oil business had a substantial surge in the early 1900s after big oil discoveries were made in the Middle East and other places. The transportation of gas and oil around the world was made possible by the creation of pipelines and tankers, which made this business truly global.
                </p>
                
                    <p className='font-large font-bold py-3'>Experience</p>
                <p className='font-grey py-2'>
                For many years, one of the most significant sectors in the global economy has been the oil and gas sector. It includes the distribution of oil and gas resources as well as their discovery, extraction, and refinement. Over time, the industry has experienced tremendous growth as new technologies and procedures are continuously used to increase production and efficiency.
A fully functional community website with extensive customization options for artists The depletion of natural resources is one of the main issues facing the oil and gas sector. The industry has had to make significant investments in new field development and exploration as well as enhancing the recovery rates of already-existing fields due to the depletion of oil and gas reserves.
                </p>
                <p className='font-grey py-2'>
                Growing public awareness of the effects that oil and gas extraction has on the environment has been a significant additional issue for the sector. As a result, there is now more regulation and pressure to discover greener energy sources and cut greenhouse gas emissions.
The oil and gas sector continues to be vital to the world economy in spite of these obstacles. The industry boosts the economy and creates jobs, and its products are used in everything from the production of electricity to transportation. For the foreseeable future, the industry will continue to change as new technologies and energy sources become available, but it will still be a significant participant in the energy sector.
                </p>
                

              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Oilpage