import React from 'react'
import Navbar from '../../Home/Navbar'
import Oilpage from './Oilpage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'

function Oil() {
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Oilpage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Oil