import React, { useEffect } from 'react'
import Navbar from '../Home/Navbar'
import BannerAbout from './BannerAbout'
import About from "../Home/About"
import AboutWintech from './AboutWintech'
import Vision from './Vision'
import AboutPeopel from './AboutPeopel'
import Footer from "../Footer/Footer"
import Icons from '../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function MainAbout() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <BannerAbout />
        <div className="py-14">
        <About  />
        </div>
        <div data-aos="fade-up" className='pb-14'>
          <AboutWintech />
        </div>
        <div data-aos="fade-up" className='pb-14'>
          <Vision />
        </div>
        <div  className='p-14'>
          <AboutPeopel />
        </div>
        <Icons />
        <Footer />
    </div>
  )
}

export default MainAbout