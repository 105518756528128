import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/AutoCAD.png"
import { Link } from 'react-router-dom'

function Autocadpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannercad bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>Autocad Design </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Autocad Design </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Autocad Design   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Review</p>
                <p className='font-grey py-2'>
                Professionals in a variety of industries, including architecture, engineering, and construction, utilize AutoCAD, a potent computer-aided design (CAD) program, to produce accurate 2D and 3D drawings. It provides a number of features and tools that enable users to accurately and efficiently develop and document their ideas.
                </p>
                <p className='py-2 font-grey'>
                Because of AutoCAD's intuitive and adaptable UI, users may customize their workplace to meet their unique requirements. In addition to more complex tools like splines, hatches, and blocks, the software provides a variety of drawing tools like lines, circles, arcs, and polylines. In order to assist users in organizing and annotating their designs, it also has capabilities like dimensioning, annotation, and layer management.
                </p>
                <p className='py-2 font-gry'>
                Impressive 3D modeling features in AutoCAD enable users to easily design complicated 3D models. Along with surface modeling capabilities like blend, sweep, and loft, it provides a variety of solid modeling techniques like extrude, revolve, sweep, and loft.
All things considered, AutoCAD is a great piece of software for experts in a variety of fields that need to be able to design accurately and precisely. It is a useful tool for design and drafting because of its variety of tools and capabilities, as well as its interoperability with other software applications.
</p>
                
            
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                  <li><Link>Mobile Advertising</Link></li>
                  <li><Link>Data science</Link></li>
                  <li><Link>Android app development</Link></li>
                  <li><Link>Digital Marketing</Link></li>
                  <li><Link>Ios Application Development</Link></li>
                  <li><Link>Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Autocadpage