import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import Oraclepage from './Oraclepage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Oracle() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className="pb-14">
            <Oraclepage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Oracle