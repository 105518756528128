import React from 'react'
import Home from './Components/Home/Home'
import { BrowserRouter, Route, Routes} from 'react-router-dom'
import MainAbout from './Components/About/MainAbout'
import ITStaffing from "./Components/ITStaffing/ITStaffing"
import Product from './Components/Services/Product/Product'
import BigData from './Components/Services/BigData/BigData'
import Enterprice from './Components/Services/Enterprice/Enterprice'
import Cloud from './Components/Services/Cloud/Cloud'
import Mobile from "./Components/Services/Mobile/Mobile"
import Testing from "./Components/Services/Testing/Testing"
import UIUX from "./Components/Services/UIUX/UIUX"
import Bsfi from './Components/Industries/Bfsi/Bsfi'
import Education from './Components/Industries/Education/Education'
import Energy from './Components/Industries/Energy/Energy'
import Media from './Components/Industries/Media/Media'
import Health from './Components/Industries/Health/Health'
import Oil from './Components/Industries/Oil/Oil'
import Retailing from './Components/Industries/Retailing/Retailing'
import Shiping from './Components/Industries/Shiping/Shiping'
import Travel from './Components/Industries/Travel/Travel'
import Microsoft from './Components/Technologies/Microsoft/Microsoft'
import Java from './Components/Technologies/Java/Java'
import Php from './Components/Technologies/Php/Php'
import IOS from './Components/Technologies/IOS/IOS'
import Andriod from './Components/Technologies/Android/Andriod'
import Python from './Components/Technologies/Python/Python'
import Node from './Components/Technologies/Node/Node'
import Oracle from './Components/Technologies/Oracle/Oracle'
import Salesforce from './Components/Technologies/Salesforce/Salesforce'
import DataScience from './Components/Technologies/Datascience/DataScience'
import Sap from './Components/Technologies/Sap/Sap'
import Autocad from './Components/Technologies/Autocad/Autocad'
import ContactUs from './Components/Contact/ContactUs'
import Location from './Components/Contact/Location'
import H1 from './Components/H1/H1'

function App(props) {
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path="about" element={<MainAbout />} />
      <Route path='it' element={<ITStaffing /> } />
      <Route path="service" element={<Product />} />
      <Route path='bigdata' element={<BigData />} />
      <Route path='enter' element={<Enterprice />} />
      <Route path="cloud" element={<Cloud />} />
      <Route path="mobile" element={<Mobile />} />
      <Route path="testing" element={<Testing />} />
      <Route path="uiux" element={<UIUX />} />
      <Route path="bsfi" element={<Bsfi />} />
      <Route path="education" element={<Education />} />
      <Route path="energy" element={<Energy />} />
      <Route path='media' element={<Media />} />
      <Route path="health" element={<Health />} />
      <Route path="oil" element={<Oil />} />
      <Route path="retail" element={<Retailing />} />
      <Route path="shiping" element={<Shiping />} />
      <Route path="travel" element={<Travel />} />
      <Route path="microsoft" element={<Microsoft />} />
      <Route path="java" element={<Java />} />
      <Route path="php" element={<Php />} />
      <Route path="ios" element={<IOS />} />
      <Route path="android" element={<Andriod />} />
      <Route path="python" element={<Python />} />
      <Route path="node" element={<Node />} />
      <Route path="oracle" element={<Oracle />} />
      <Route path="salesforce" element={<Salesforce />} />
      <Route path="datascience" element={<DataScience />} />
      <Route path="sap" element={<Sap />} />
      <Route path="autocad" element={<Autocad />} />
      <Route path="contact" element={<ContactUs />} />
      <Route path="location" element={<Location />} />
      <Route path="h1b" element={<H1 />} />
    </Routes>
    </BrowserRouter>
  )
}

export default App