import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/venery.jpg"
import { Link } from 'react-router-dom'

function Energypage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerenergy bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Energy Utilities</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1  className='text-center theme-font font-large font-bold pt-14 pb-4'>Energy Utilities</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Energy Utilities</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                A vast array of companies and organizations engaged in the production, transmission, distribution, and retail of energy and associated services are included in the energy and utilities services sector. This sector is vital to contemporary society since it supplies homes, companies, and other organizations with necessities including water, natural gas, and electricity.
                </p>
                <p className='font-grey py-2'>
                Businesses that produce electricity through a variety of means, including nuclear power, coal, natural gas, hydroelectric power, and renewable energy sources like geothermal, solar, and wind power. Transmission and distribution companies are those who run and maintain the networks that supply homes and businesses with electricity generated at power plants.
                </p>
                    <p className='font-large font-bold py-3'> Energy Management Software Experience</p>
                <p className='font-grey py-2'>
                Software for energy management is intended to assist companies and groups in monitoring and evaluating their energy use. In addition to providing historical data analysis and real-time monitoring of energy consumption, this kind of software can help pinpoint locations where energy savings can be made.
                </p>
                <p className='font-grey py-2'>
                Energy management software comes in a variety of forms, including integrated systems that are a component of building automation systems (BAS) and standalone systems. Some energy management software can offer a more complete picture of building performance by integrating with other systems, like lighting and HVAC controls.
                </p>
                <p className='font-grey py-2'>
                Energy management software can monitor energy consumption and also offer tools for goal-setting, monitoring progress toward targets, and producing reports that illustrate cumulative energy savings. Businesses and groups aiming to lower their energy expenses and environmental effect may find this kind of software especially helpful.
                </p>
                

              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Energypage