import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/travel.jpg"
import { Link } from 'react-router-dom'

function Travelpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannertravel bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Travel- Hospitality</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Travel- Hospitality</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Travel- Hospitality</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The process of building and enhancing the facilities, services, and infrastructure associated with the travel and tourist sector is known as "travel and hospitality development." This can involve a variety of projects, including building new resorts and hotels, creating new tourism destinations, upgrading transit infrastructure, and improving overall client experiences.
                </p>
                <p className='py-2 font-grey'>
                Many nations rely heavily on the travel and hospitality sector because it generates income through tax revenues, foreign exchange profits, and the creation of jobs. Consequently, the growth of this business is frequently given top priority by governments and private investors.
                    </p>
                <p className='font-grey py-2'>
                In general, the travel and hospitality sector is always changing, and shifting customer tastes, technology breakthroughs, and worldwide trends will continue to influence this evolution.
                </p>
                
                
                    <p className='font-large font-bold py-3'>Experience </p>
                <p className='font-grey py-2'>
                The process of developing and enhancing lodging options and services for tourists is known as hospitality development. This can apply to lodging facilities, dining establishments, tour companies, and other companies that serve tourists.
A successful hospitality business is the result of a careful blend of strategic planning, industry expertise, and attention to detail. The following are important phases in the development process:
                </p>
                <p className='font-grey py-2'>
                Conduct market research to determine the requirements and inclinations of your intended audience, encompassing their travel inclinations, preferred facilities, and degree of price sensitivity. Site selection: Pick a place that will appeal to your target market and provide the resources and infrastructure your firm needs to succeed.
                </p>
                <p className='font-grey py-2'>
                Idea development: Come up with a distinctive and alluring business concept that incorporates branding, design, and marketing tactics. Obtain cash for your idea from conventional lenders or from non-traditional sources like venture capital or crowdsourcing.
                </p>
                
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Travelpage