import React, { useState } from 'react';
import CountUp from 'react-countup-es';
import "./Counter.css"
import ScrollTrigger from 'react-scroll-trigger';
// import CountUp from 'react-countup';

function Counter() {
  const[active,setactive]= useState(false)
  return (
    <ScrollTrigger onEnter={()=>setactive(true)}>
      <div className='container mx-auto py-14'>
      <div className='grid grid-cols-6 flex justify-center'>
        <div data-aos="fade-right"  className='md:col-span-2 sm:col-span-3 m-2  col-span-6  mx-2 counts num-bac py-2 rounded-lg text-center'>
          <h1 className='py-4'>Happy Clients</h1>
          <div className='flex justify-center  items-center'>
          {active && <CountUp start={0} end={20} duration={8} className='num'/>}
          <div className='flex items-center px-2 pb-1  self-center num font-bold'><p>+</p></div>
          </div>
        </div>
        <div data-aos="fade-up" className='md:col-span-2 sm:col-span-3 m-2  col-span-6  counts mx-2 py-2 num-bac rounded-lg text-center'>
          <h1 className='py-4'>Total Projects</h1>
          <div className='flex justify-center  items-center'>
          {active && <CountUp start={0} end={50} duration={8} className='num'/>}
          {/* <div className='flex items-center px-2 pb-1  self-center num font-bold'><p>+</p></div> */}
          </div>        
          </div>
        <div data-aos="fade-left" className='md:col-span-2 sm:col-span-3 m-2  col-span-6  mx-2 rounded-lg py-2 counts num-bac text-center'>
          <h1 className='py-4'>Happy Clients</h1>
          <div className='flex justify-center  items-center'>
          {active && <CountUp start={0} end={20} duration={8} className='num'/>}
          <div className='flex items-center px-2 pb-1  self-center num font-bold'><p>+</p></div>
          </div>
          </div>
      </div>
    </div>
    </ScrollTrigger>
  );
}

export default Counter;
