import Aos from 'aos'
import React, { useEffect, useRef, useState } from 'react'
import "aos/dist/aos.css"
import "../Contact/Contact.css"
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast'

function H1page() {
    useEffect(()=>{
        Aos.init()
    },[])
    const form =useRef() 
    const [isname, setisname] = useState(false)
    const [isemail, setisemail] = useState(false)
    const [iscontact, setiscontact] = useState(false)
    const [isdescription, setisdescription] = useState(false)
    const [itskills, setitskills] = useState("")
    const [experince, setexperince] = useState(false)
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [contact, setcontact] = useState("")
    const [description, setdescription] = useState("")
    const nameHandler = (event)=>{
      setname(event.target.value)
      setisname(false)
    }
    const emailHandler = (event)=>{
      setemail(event.target.value)
      setisemail(false)
    }
    const contactHandler = (event)=>{
      setcontact(event.target.value)
      setiscontact(false)
    }
    const descriptionHandler = (event)=>{
      setdescription(event.target.value)
      setisdescription(false)
    }
    const ITskillhandler = (event)=>{
      setitskills(event.target.value)
    }
    const selectHandler =(event)=>{
      const select = document.getElementById("experince")
      select[0].removeAttribute("selected")
      console.log(event.target.value)
    }
    const h1bHandler = (event) => {
      event.preventDefault();
    
      if(name.length < 3 || email.includes("@") === false){
        if (name.length < 3) {
          setisname(true);
        }
        if (email.includes("@") === false) {
          setisemail(true);
        }
      }else{
      
        emailjs.sendForm('service_8bza5xe', 'template_df95mv9', form.current, '5MKQxHFs8bcYCsx-k')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      const select = document.getElementById("experince")
      select[0].setAttribute("selected", true)
        toast.success(<p className='text-center'>Successfully sent <br/> Wintechsoft will contact you soon</p>)
        setcontact("")
        setname("")
        setemail("")
        setdescription("")
        setitskills("")
        setexperince("")
        
      }
    };
    
    
  return (
    <div >
      
      <div>
        <Toaster position="top-right"
  reverseOrder={false}/>
        <div className='flex items-center justify-center bannerh1'>
            <div>
            <h1 className='font-white text-center py-2 font-bold font-large'>WintecSoft  <span className='theme-sec px-1'> H1B </span>  application</h1>
            {/* <p className='font-white text-center '></p> */}
            </div>
        </div>
    </div>
    <div>
      <h1 className='text-center pt-14 font-bold theme-font font-large'>H-1B</h1>
    </div>
        <div data-aos-duration="1000" data-aos="zoom-in" className='container mx-auto px-1'>
    <div className='flex justify-center pt-14'>
    <form className="w-full max-w-lg" ref={form} onSubmit={h1bHandler}>
  <div className="flex flex-wrap -mx-3 mb-6">
    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-2">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"  for="grid-first-name">
        Your Name
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" onChange={nameHandler} id="grid-first-name" value={name} name='name' type="text" placeholder="name" />
      {isname && <p className='text-red-600 text-sm'>Invalid name</p>}
    </div>
    <div className="w-full md:w-1/2 px-3">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Enter Email
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3  mb-3  px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name"  onChange={emailHandler} value={email} name="email" type="email" placeholder="example@gmail.com"/>
      {isemail && <p className='text-red-600 text-sm'>Invalid Email</p>}
    </div>
  </div>
    <div className="w-full mb-6">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Contact Number
      </label>
      <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="number" pattern="\d*" maxlength="10" name='contact' onChange={contactHandler} value={contact}  placeholder="123 123 1234"/>
      {/* {iscontact && <p className='text-red-600 text-sm'>Invalid Number</p>} */}
    </div>

    <div className='my-6'>
    <label for="experince" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total Experience</label>
<select onChange={selectHandler} id="experince" name='experince'   class=" border  block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ">
  <option value="">Experience</option>
  <option value="0">0</option>
  <option value="1">1</option>
  <option value="2">2</option>
  <option value="3">3</option>
  <option value="4">4</option>
  <option value="5">5</option>
  <option value="6">6</option>
  <option value="7">7</option>
  <option value="8">8</option>
  <option value="9">9</option>  
  <option value="10">10</option>
  <option value="11">11</option>
  <option value="12">12</option>
  <option value="13">13</option>
  <option value="14">14</option>
  <option value="15">15</option>
  <option value="16">16</option>
  <option value="17">17</option>
  <option value="18">18</option>
  <option value="19">19</option>
  <option value="20">20</option>
 
</select>
    </div>

  <div>
  <div className="w-full mb-6">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        IT Skills
      </label>
      <input onChange={ITskillhandler} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" value={itskills}  name='itskills'   placeholder=""/>
      {/* {iscontact && <p className='text-red-600 text-sm'>Invalid Number</p>} */}
    </div>
  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
        Description
      </label>
      <textarea  onChange={descriptionHandler}
    name="description"
    minlength="500"
    value={description} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-password" rows={4} ></textarea>
    {/* {isdescription && <p className='text-red-600 text-sm'>Invalid Description</p>} */}
    <div className='flex justify-center'>
      <button className='btn py-2 px-3 text-white bg-black border rounded-xl'>Submit</button>
    </div>
  </div>
</form>
    </div>

    </div>
    </div>
  )
}

export default H1page