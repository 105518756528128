import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/android-199225_1280.jpg"
import { Link } from 'react-router-dom'

function Andriodpage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerandriod bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>Android App Development </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Android App Development </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Android App Development   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The process of developing software programs for the Android operating system is known as Android app development. Most smartphones and tablets in use today run Android, an open-source operating system developed by Google. Writing code in programming languages like Java, Kotlin, or C++ and compiling it into an executable binary file that can be installed and used on Android devices is the process of developing an Android app.
                </p>
                <p className='py-2 font-grey'>
                One of the well-known Android app development companies that provides expert services is Wintecsoft Solutions Pvt Ltd. Our talented group of Android developers keeps us abreast of the newest releases and trends, enabling us to produce apps of the highest caliber. We advise and create apps to satisfy the business needs and expectations of our clients using our extensive research and analysis. With practical knowledge of many different tools and technologies, we create robust, scalable, and customizable Android apps that work with any Android device. We have successfully completed more than 160 projects to far and provide android app development services worldwide.
                </p>
                <p className='font-grey py-2'>
                Developing the app's concept include deciding on its function, features, and intended user base. Creating wireframes and mockups to specify the user interface (UI) and user experience (UX) of the app is part of the design process.
                </p>
                <p className='py-3 font-bold'>
                Our Android App development services include:
                </p>
                
                <p className='py-1 font-grey'>Ideation and Conceptualization</p>
                <p className='font-grey py-1'>
                UI/UX Design
                </p>
                <p className='font-grey py-1'>
                Testing and Quality Assurance
                </p>
                <p className='font-grey py-1'>
                Deployment and Launch
                </p>
                <p className='font-grey py-1'>
                Maintenance and Support
                </p>
                <p className='font-grey py-1'>
                Customization and Integration:
                </p>
                <p className='font-grey py-1'>
                App Marketing and Promotion
                </p>
               
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Andriodpage