import React from 'react'
import "../Industries.css"
import img from "../../../assets/images/vmedia.png"
import { Link } from 'react-router-dom'

function Mediapage() {
  return (
    <div>
    <div className='flex items-center justify-center bannermedia bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Industries</h1>
        <p className='font-white text-center '>Media Entertainment</p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>Media Entertainment</h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>Media Entertainment</p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                The worldwide media and entertainment sector encompasses a wide range of media and entertainment, including publishing, advertising, social media, gaming, cinema, music, and television. It is a huge and quickly changing industry. Consumer desire for top-notch experiences and content that are available across several platforms—including social media, online streaming services, and traditional media outlets—drives this market.
                </p>
                <p className='font-grey py-2'>
                The business has seen tremendous growth and change in recent years, primarily due to the quick development of digital technology and the rising desire for individualized, immersive, and interactive experiences. Because of this, the business has grown more complicated and competitive, with a wide range of participants ranging from independent producers and content creators to large media conglomerates.
                </p>
                <p className='font-grey py-2'>All things considered, the global media and entertainment sector is a vibrant, quick-paced sector that is always changing to accommodate customers' shifting demands and tastes.
                </p>
                    <p className='font-large font-bold py-3'>Experience</p>
                <p className='font-grey py-2'>
                The worldwide media and entertainment industry is a dynamic and diversified field that includes a wide range of companies and endeavors, such as publishing, gaming, sports, cinema, music, television, and advertising. This multibillion dollar sector is expanding quickly due to shifting consumer preferences, technological advancements, and the growing appeal of digital media.
                </p>
                <p className='font-grey py-2'>
                The industry has experienced many difficulties recently, including the emergence of streaming services like Netflix, Amazon Prime, and Disney+ and the disruption of conventional economic structures by digital technologies. Another significant effect of the COVID-19 epidemic on the business was the postponement of events, the closing of movie theaters, and a drop in advertising revenue.
                </p>
                <p className='font-grey py-2'>
                Notwithstanding these obstacles, the media and entertainment sector continues to play a vital role in the world economy, influencing trade, employment, and the expression of culture. It is anticipated that the industry will keep developing and adapting to new markets, evolving technology, and shifting consumer tastes, posing both possibilities and difficulties for both consumers and enterprises.
                </p>
                

              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Mediapage