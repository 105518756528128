import React, { useEffect } from 'react'
import Phppage from './Phppage'
import Footer from '../../Footer/Footer'
import Navbar from '../../Home/Navbar'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Php() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'>
            <Phppage />
        </div>
        <Icons/>
        <Footer />
    </div>
  )
}

export default Php