import React, { useEffect } from 'react'
import Navbar from '../../Home/Navbar'
import Sappage from './Sappage'
import Footer from '../../Footer/Footer'
import Icons from '../../Icons/Icons'
import Aos from 'aos'
import "aos/dist/aos.css"

function Sap() {
  useEffect(()=>{
    Aos.init()
  },[])
  return (
    <div>
        <Navbar />
        <div className='pb-14'> 
            <Sappage />
        </div>
        <Icons/>
        <Footer/>
    </div>
  )
}

export default Sap