import React from 'react'
import "../Technologies.css"
import img from "../../../assets/images/vphp.png"
import { Link } from 'react-router-dom'

function Phppage() {
  return (
    <div>
    <div className='flex items-center justify-center bannerphp bannerimg'>
        <div>
        <h1 className='font-white text-center py-2 font-bold font-large'>Technologies</h1>
        <p className='font-white text-center '>PHP Development Services </p>
        </div>
    </div>
    <div data-aos-duration="1000" data-aos="fade-down">
            <h1 className='text-center theme-font font-large font-bold pt-14 pb-4'>PHP Development Services </h1>
            <div className='flex justify-center'>
            <img src={img} className='img-fluid w-screen imagess'  />
            </div>
        </div>
        <div>
          <div className='container mx-auto'>
            {/* <p className='font-theme text-center font-bold py-3'>PHP Development Services   </p> */}
            <div className='grid grid-cols-6'>
              <div data-aos-duration="1000" data-aos="fade-right" className='md:col-span-4 col-span-6 mx-3'>
                <p className='font-large theme-font py-2'>Overview</p>
                <p className='font-grey py-2'>
                A popular server-side scripting language for web development is PHP. Since its creation by Rasmus Lerdorf in 1994, it has grown to rank among the most widely used programming languages today.
                </p>
                <p className='py-2 font-grey'>
                "Hypertext Preprocessor," or PHP, is a programming language used to generate dynamic webpages that communicate with databases and other online services. Since it is an open-source language, anyone can alter its source code to meet their own requirements.
                </p>
                <p className='font-grey py-2'>
                PHP's adaptability is one of its advantages since it can be utilized for a wide range of web development activities, such as constructing straightforward websites, e-commerce application development, content management system creation, and more. Additionally, it works with a wide range of operating systems and web servers.
                </p>
                <p className='py-2 font-grey'>
                In PHP development, major frameworks and technologies like Laravel, Symfony, CodeIgniter, and WordPress are frequently utilized. Furthermore, there is a sizable and vibrant PHP development community that uses conferences, user organizations, and online forums to exchange information and resources.
                </p>
                <p className='font-grey py-2'>
                Our team of proficient PHP developers has offered our PHP Web Design and Development services to create a personalized and easily navigable application.
                </p>
                <p className='py-1 font-grey'>Custom web application development</p>
                <p className='font-grey py-1'>
                Content Management Systems        
                </p>
                <p className='font-grey py-1'>
                E-commerce development
                </p>
                <p className='font-grey py-1'>
                API development:
                </p>
                <p className='font-grey py-1'>
                PHP framework development:
                </p>
                <p className='font-grey p-1'>
                Maintenance and support:
                </p>
               
              </div>
              <div data-aos-duration="1000" data-aos="fade-left" className='md:col-span-2 col-span-6 mx-3 service-list'>
              <p className='font-large theme-font py-2'>Services</p> 
                <ul>
                <li><Link to="/mobile">Mobile Advertising</Link></li>
                  <li><Link to="/datascience">Data science</Link></li>
                  <li><Link to="/android">Android app development</Link></li>
                  <li><Link to="/media">Digital Marketing</Link></li>
                  <li><Link to="/ios">IOS Application Development</Link></li>
                  <li><Link to="/java">Java</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
</div>
  )
}

export default Phppage