import React from 'react'
import "./Footer.css"
import "../../index.css"
import img from "../../assets/images/wintech.png"
import { Link } from 'react-router-dom'
import verify from "../../assets/images/verify.png"

function Footer() {
  return (
    <div data-aos="zoom-in" data-aos-duration="1500" data-aos-once={true} className='theme-bg footer'>
        <div className='container mx-auto py-14'>
            <div className='grid grid-cols-4 '>
                <div className='lg:col-span-1 flex justify-center md:col-span-4 sm:col-span-4 col-span-4 flex items-center  rounded-md p-3 m-3 height100'>
                    <div className='foot-img'>
                        <img src={img} alt="img" className=' img-fluid' />
            </div>
                </div>
                <div className='lg:col-span-1 md:col-span-2 sm:col-span-4 col-span-4 p-3 m-3'>
                    <p className='font-small footer-headline font-white font-bold py-3 headline'>Get In Touch</p>
                    <ul>
                        <li className='flex py-2'>
                            <div className='font-white font-small'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path  strokeLinecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path  strokeLinecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>
                            </div>
                            <div className='font-white pl-2'>
                            1320 Arrow Point Dr Suite 501 # TW115 Cedar Park,
TX 78613
                            </div>
                        </li>
                        <li className='flex py-2'>
                            <div className='font-white font-small'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path  strokeLinecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
  <path  strokeLinecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
</svg>
                            </div>
                            <div className='font-white pl-2'>
                            RAM SVR, Plot No 4/2, Sector 1, Madhapur, HUDA Techno Enclave, HITEC City, Hyderabad, Telangana 500081
                            </div>
                        </li>
                        <li  className='flex pt-2'>
                            <div className='font-white font-small'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                <path fill-rule="evenodd" d="M17.834 6.166a8.25 8.25 0 100 11.668.75.75 0 011.06 1.06c-3.807 3.808-9.98 3.808-13.788 0-3.808-3.807-3.808-9.98 0-13.788 3.807-3.808 9.98-3.808 13.788 0A9.722 9.722 0 0121.75 12c0 .975-.296 1.887-.809 2.571-.514.685-1.28 1.179-2.191 1.179-.904 0-1.666-.487-2.18-1.164a5.25 5.25 0 11-.82-6.26V8.25a.75.75 0 011.5 0V12c0 .682.208 1.27.509 1.671.3.401.659.579.991.579.332 0 .69-.178.991-.579.3-.4.509-.99.509-1.671a8.222 8.222 0 00-2.416-5.834zM15.75 12a3.75 3.75 0 10-7.5 0 3.75 3.75 0 007.5 0z" clip-rule="evenodd" />
                            </svg>
                            </div>
                            <div className='font-white pl-2'>
                            hr@wintecsoft.net
                            </div>
                        </li>
                    </ul>
                    <p className='pt-2 font-white'><Link className='view' to="/location">View more...</Link></p>
                </div>
                <div className='lg:col-span-1 md:col-span-1 sm:col-span-2 col-span-4 p-3 m-3 footer-links' >
                    <p  className='font-small footer-headline font-white font-bold py-3 headline'>Quick Links</p>
                    <ul className=' px-3'>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About us</Link></li>
                        <li><Link to="/service">Our Services</Link></li>
                        <li><Link to="/contact">Contact us</Link></li>
                    </ul>
                </div>
                <div className='lg:col-span-1 md:col-span-1 sm:col-span-2 col-span-4 p-3 m-3 footer-links' >
                    <p  className='font-small footer-headline font-white font-bold py-3 headline'>Services</p>
                    <ul className=' px-3'>
                        <li><Link to="/service">Product Development</Link></li>
                        <li><Link to="/bigdata">Big Data</Link></li>
                        <li><Link to="/cloud">Cloud Services</Link></li>
                        <li><Link to="/enter">Enterprise Solutions</Link></li>
                        <li><Link to="/mobile">Mobile Apps</Link></li>
                        <li><Link to="/uiux">UI And UX Development</Link></li>
                    </ul>
                    <img src={verify} className='h-10 mt-10' />
                    
                </div>
                <div>

                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Footer